import { useMemo } from "react";
import type { StudioErrorOptions } from "~/errors";
import { StudioError } from "~/errors";
import type { Topic } from "~/lqs";
import type { AsyncOperationSnapshot } from "../../../types";
import type { ChartPanel, ChartTopicDescriptor } from "../../panels";
import { VisualizationType } from "../../panels";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../../playback";
import type {
  AllRecordsRequest,
  LimitedRecordsRequest,
  PlayerRecord,
  RecordStore,
  StoreSnapshot,
} from "../../record-store";
import { useStoreSnapshot } from "../../record-store";
import type { SampleFrequencyValue, TimestepValue } from "../../types";
import { SampleFrequency, Timestep } from "../../types";
import { useVisualizationStoreParams } from "../context";

interface ChartErrorParameters extends StudioErrorOptions {
  source: "window" | "overview";
}

export class ChartError extends StudioError {
  readonly source: "window" | "overview";

  constructor({ message, cause, source }: ChartErrorParameters) {
    super({ message, cause });

    this.source = source;
  }
}

interface StoreRequest {
  topic: Topic;
  descriptor: ChartTopicDescriptor;
  showOverview: boolean;
  frequency: SampleFrequencyValue;
  count: number;
  timestamp: bigint;
}

interface ChartData {
  window: Array<PlayerRecord<"default">>;
  overview: Array<PlayerRecord<"default">> | null;
}

function createRecordsRequests(
  topic: Topic,
  showOverview: boolean,
  frequency: SampleFrequencyValue,
  count: number,
  timestamp: bigint,
): {
  windowRequest: LimitedRecordsRequest<"default">;
  overviewRequest: AllRecordsRequest<"default"> | null;
} {
  return {
    windowRequest: {
      recordType: "default",
      topicId: topic.id,
      frequency,
      timestamp,
      count,
    },
    overviewRequest: showOverview
      ? {
          recordType: "default",
          topicId: topic.id,
          frequency: SampleFrequency.Second,
          all: true,
        }
      : null,
  };
}

function subscribeToStore(
  topic: Topic,
  showOverview: boolean,
  frequency: SampleFrequencyValue,
  count: number,
  timestamp: bigint,
  limit: number,
  prefetchBehind: number,
  prefetchAhead: number,
  store: RecordStore,
  notify: () => void,
): () => void {
  const { windowRequest, overviewRequest } = createRecordsRequests(
    topic,
    showOverview,
    frequency,
    count,
    timestamp,
  );

  const unsubscribeFns = [
    store.subscribe({
      ...windowRequest,
      limit,
      topicStartTime: topic.startTime,
      topicEndTime: topic.endTime,
      prefetchBehind,
      prefetchAhead,
      notify,
    }),
    overviewRequest != null &&
      store.subscribe({
        ...overviewRequest,
        limit: 100,
        topicStartTime: topic.startTime,
        topicEndTime: topic.endTime,
        notify,
      }),
  ].filter((fn) => typeof fn === "function");

  return () => {
    unsubscribeFns.forEach((fn) => fn());
  };
}

function getSnapshot(
  topic: Topic,
  showOverview: boolean,
  frequency: SampleFrequencyValue,
  count: number,
  timestamp: bigint,
  store: RecordStore,
): AsyncOperationSnapshot<ChartData> {
  const { windowRequest, overviewRequest } = createRecordsRequests(
    topic,
    showOverview,
    frequency,
    count,
    timestamp,
  );

  const windowResponse = store.getRecords(windowRequest);
  const overviewResponse =
    overviewRequest == null ? null : store.getRecords(overviewRequest);

  if (windowResponse.status === "rejected") {
    return {
      status: "rejected",
      reason: new ChartError({
        cause: windowResponse.reason,
        source: "window",
      }),
    };
  }

  if (overviewResponse?.status === "rejected") {
    return {
      status: "rejected",
      reason: new ChartError({
        cause: overviewResponse.reason,
        source: "overview",
      }),
    };
  }

  if (windowResponse.status === "pending") {
    return windowResponse;
  }

  if (overviewResponse?.status === "pending") {
    return overviewResponse;
  }

  return {
    status: "fulfilled",
    value: {
      window: windowResponse.value,
      overview: overviewResponse?.value ?? null,
    },
  };
}

export function useChartRecords({
  panel,
  topic,
}: {
  panel: ChartPanel;
  topic: Topic;
}): [
  chartRecordsSnapshot: StoreSnapshot<StoreRequest, ChartData>,
  isPlaceholderSnapshot: boolean,
] {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const { count, limit, prefetchBehind, prefetchAhead, timestepOverride } =
    useVisualizationStoreParams(VisualizationType.Chart);

  const frequency = getFrequencyForTimestep(
    timestepOverride ?? playbackSettings.timestep,
  );

  const storeSnapshotResult = useStoreSnapshot(
    useMemo(() => {
      const {
        topics: [descriptor],
      } = panel;

      const request: StoreRequest = {
        topic,
        descriptor,
        showOverview: panel.showOverview,
        frequency,
        count,
        timestamp: playbackSource.timestamp,
      };

      return {
        request,
        subscribe: subscribeToStore.bind(
          null,
          topic,
          panel.showOverview,
          frequency,
          count,
          playbackSource.timestamp,
          limit,
          prefetchBehind,
          prefetchAhead,
        ),
        getSnapshot: getSnapshot.bind(
          null,
          topic,
          panel.showOverview,
          frequency,
          count,
          playbackSource.timestamp,
        ),
      };
    }, [
      panel,
      topic,
      frequency,
      count,
      playbackSource.timestamp,
      limit,
      prefetchBehind,
      prefetchAhead,
    ]),
  );

  return [storeSnapshotResult.snapshot, storeSnapshotResult.isPlaceholder];
}

function getFrequencyForTimestep(
  timestep: TimestepValue,
): SampleFrequencyValue {
  return {
    [Timestep.Second]: SampleFrequency.Second,
    [Timestep.HalfSecond]: SampleFrequency.HalfSecond,
    [Timestep.Decisecond]: SampleFrequency.Decisecond,
  }[timestep];
}
