import { StudioError } from "~/errors";
import { invariant } from "~/lib/invariant";
import { emplace } from "~/lib/std";
import type { LimitedRecordsRequest } from "./types";

export class FetchLoopError extends StudioError {
  constructor() {
    super({ message: "Infinite fetching loop detected in record store" });
  }
}

type SubscriptionIdentifiers = Pick<
  LimitedRecordsRequest<any>,
  "timestamp" | "count"
>;

const MAX_INDIVIDUAL_FETCH_ITERATION_COUNT = 15;
const MAX_COMBINED_FETCH_ITERATION_COUNT = 25;

/**
 * Detect if store is likely in an infinite fetching loop and terminate
 * further requests.
 */
export class LoopDetector {
  readonly #subscriptionFetchCountsMap = new Map<
    string,
    {
      instances: number;
      timeoutId: ReturnType<typeof setTimeout> | null;
      desc: number;
      asc: number;
    }
  >();

  static createKey(identifiers: SubscriptionIdentifiers): string {
    return `${identifiers.count}:${identifiers.timestamp}`;
  }

  addSubscription(identifiers: SubscriptionIdentifiers): void {
    const key = LoopDetector.createKey(identifiers);

    const entry = emplace(this.#subscriptionFetchCountsMap, key, {
      insert() {
        return {
          instances: 0,
          timeoutId: null,
          desc: 0,
          asc: 0,
        };
      },
    });
    entry.instances++;

    if (entry.timeoutId !== null) {
      clearTimeout(entry.timeoutId);
      entry.timeoutId = null;
    }
  }

  removeSubscription(identifiers: SubscriptionIdentifiers): void {
    const key = LoopDetector.createKey(identifiers);

    const entry = this.#subscriptionFetchCountsMap.get(key);
    invariant(entry !== undefined, `Expected an entry with key "${key}"`);

    entry.instances--;

    // Don't want to keep fetch counts around indefinitely, otherwise there
    // would be a memory leak. However, since React may unsubscribe and
    // resubscribe multiple times for the same conceptual subscription, it's
    // not sufficient to just delete the map entry when the instance count
    // reaches 0.
    if (entry.instances === 0) {
      if (entry.desc > 0 || entry.asc > 0) {
        // There's useful information recorded here since this subscription has
        // caused at least one fetch request to be made. Delete it from the map
        // after 15 seconds to be more certain the entry is no longer needed.
        entry.timeoutId = setTimeout(
          this.#evictSubscriptionFetchCounts.bind(this, key),
          15_000,
        );
      } else {
        // No fetch requests have been made because of this subscription so
        // delete it immediately rather than maintain a timeout. Even if React
        // is about to resubscribe, there was no information worth preserving.
        this.#evictSubscriptionFetchCounts(key);
      }
    }
  }

  recordIteration(
    identifiers: SubscriptionIdentifiers,
    direction: "desc" | "asc",
  ): void {
    const key = LoopDetector.createKey(identifiers);

    const entry = this.#subscriptionFetchCountsMap.get(key);
    invariant(entry !== undefined, `No entry found with key "${key}"`);

    entry[direction]++;

    if (entry[direction] > MAX_INDIVIDUAL_FETCH_ITERATION_COUNT) {
      throw new FetchLoopError();
    }

    if (entry.desc + entry.asc > MAX_COMBINED_FETCH_ITERATION_COUNT) {
      throw new FetchLoopError();
    }
  }

  reset(): void {
    this.#subscriptionFetchCountsMap.forEach((entry) => {
      entry.desc = 0;
      entry.asc = 0;
    });
  }

  #evictSubscriptionFetchCounts(key: string): void {
    this.#subscriptionFetchCountsMap.delete(key);
  }
}
