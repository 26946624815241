import type { Topic } from "~/lqs";
import { SourceTopicSelect } from "../../components";
import type { TimelinePanel } from "../../panels";

export function TimelineVisualizationControls({
  panel,
  topic,
}: {
  panel: TimelinePanel;
  topic: Topic;
}) {
  return <SourceTopicSelect panel={panel} topic={topic} />;
}
