import { useMemo } from "react";
import { useSyncExternalStoreWithSelector } from "use-sync-external-store/with-selector";
import { identity, isEqual } from "~/lib/std";
import type { AsyncOperationSnapshot } from "~/types";
import { useRecordStoreContext } from "./context";
import type { RecordStore } from "./store";
import type { FulfilledSnapshot, StoreSnapshot } from "./types";

export type CanReuseSnapshotFn<TRequest, TValue> = (
  prevSnapshot: FulfilledSnapshot<StoreSnapshot<TRequest, TValue>>,
  request: TRequest,
) => boolean;

export interface UseStoreSnapshotParams<TRequest, TValue> {
  request: TRequest;
  subscribe: (store: RecordStore, notify: () => void) => () => void;
  getSnapshot: (store: RecordStore) => AsyncOperationSnapshot<TValue>;
  canReuseSnapshot?: CanReuseSnapshotFn<TRequest, TValue>;
}

export function useStoreSnapshot<TRequest, TValue>({
  request,
  subscribe: subscribeParam,
  getSnapshot: getSnapshotParam,
  canReuseSnapshot,
}: UseStoreSnapshotParams<TRequest, TValue>): {
  snapshot: StoreSnapshot<TRequest, TValue>;
  isPlaceholder: boolean;
} {
  const store = useRecordStoreContext();

  const { subscribe, getSnapshot, areSnapshotsEqual } = useMemo(
    () => ({
      subscribe: subscribeParam.bind(null, store),
      getSnapshot() {
        return {
          request,
          ...getSnapshotParam(store),
        };
      },
      areSnapshotsEqual(
        prevSnapshot: StoreSnapshot<TRequest, TValue>,
        nextSnapshot: StoreSnapshot<TRequest, TValue>,
      ): boolean {
        if (
          nextSnapshot.status === "pending" &&
          prevSnapshot.status === "fulfilled" &&
          canReuseSnapshot?.(prevSnapshot, request) !== false
        ) {
          // This is stretching the idea of the snapshots being equal but it's
          // useful: if a panel is showing data then switches to a pending state
          // (for example, moving to a timestamp where records aren't yet
          // fetched) we want to continue showing the previous data on screen
          // until the new data is ready. By returning `true` here, we're
          // telling React the snapshots are "equal" in the sense nothing has
          // changed about what should be visualized.
          return true;
        }

        // In all other cases, perform a deep equality check on the entire snapshot.
        return isEqual(prevSnapshot, nextSnapshot);
      },
    }),
    [request, subscribeParam, getSnapshotParam, canReuseSnapshot, store],
  );

  const snapshot = useSyncExternalStoreWithSelector(
    subscribe,
    getSnapshot,
    undefined,
    identity,
    areSnapshotsEqual,
  );

  return {
    snapshot,
    isPlaceholder:
      snapshot.status === "fulfilled" && !isEqual(request, snapshot.request),
  };
}
