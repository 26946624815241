import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { produce } from "immer";
import { useAuthenticationStatus } from "~/domain/auth";
import {
  useCurrentDsmUser,
  useDsmMutation,
  useDsmQuery,
  useDsmQueryClient,
} from "~/dsm";
import { invariant } from "~/lib/invariant";
import { get } from "~/lib/std";
import {
  type LogDataResponse,
  logKeys,
  useCurrentDataStore,
  useDataStoreClients,
} from "~/lqs";
import type { Maybe } from "~/types";
import { usePlayerLog, usePlayerParams } from "../hooks";
import type * as sdkTypes from "./sdk";
import {
  createLayoutProfile,
  deleteLayoutProfile,
  deserializeLayoutProfileDescriptors,
  getLayoutProfiles,
  serializeLayoutProfileDescriptors,
} from "./sdk";
import type { LayoutProfileDescriptor } from "./types";

const layoutProfileKeys = {
  all: ["layout-profiles"] as const,
  lists: () => [...layoutProfileKeys.all, "list"] as const,
  user: () => [...layoutProfileKeys.lists(), "user"] as const,
  dataStore: () => [...layoutProfileKeys.lists(), "datastore"] as const,
  fetches: () => [...layoutProfileKeys.all, "fetch"] as const,
  fetch: (name: Maybe<sdkTypes.LayoutProfileDescriptor["name"]>) =>
    [...layoutProfileKeys.fetches(), name] as const,
};

export function useUserLayoutProfiles(): UseQueryResult<
  // Once profiles are back-end resources, it could be possible for a future
  // layout profile descriptor to be encountered, so go ahead and define the
  // return type that way now to be prepared.
  ReadonlyArray<sdkTypes.FoundLayoutProfileDescriptor>
> {
  const authenticationStatus = useAuthenticationStatus();

  // The current user's ID will be used to filter once profiles are
  // back-end resources.
  const currentDsmUser = useCurrentDsmUser();
  const dsmUserId = currentDsmUser.data?.data?.id;

  return useDsmQuery({
    queryKey: layoutProfileKeys.user(),
    queryFn: getLayoutProfiles,
    // Once profiles become back-end resources, unauthenticated users won't
    // have any of their own profiles to fetch.
    enabled: authenticationStatus === "unauthenticated" || dsmUserId != null,
  });
}

export function useDataStoreLayoutProfiles(): UseQueryResult<
  ReadonlyArray<sdkTypes.FoundLayoutProfileDescriptor>
> {
  const currentDataStore = useCurrentDataStore({ strict: false });

  return useDsmQuery({
    queryKey: layoutProfileKeys.dataStore(),
    queryFn(): ReadonlyArray<sdkTypes.FoundLayoutProfileDescriptor> {
      if (currentDataStore == null) {
        return [];
      }

      return deserializeLayoutProfileDescriptors(
        get(currentDataStore.context, "studio.layout_profiles", []),
      );
    },
  });
}

export function useDefaultLayoutProfile(): UseQueryResult<sdkTypes.FoundLayoutProfileDescriptor | null> {
  const logContextQuery = usePlayerLog(({ data: { context } }) => {
    const rawName: unknown = get(context, "studio.default_layout_profile");

    const defaultProfileName = rawName == null ? null : String(rawName);

    let logProfiles: ReadonlyArray<sdkTypes.FoundLayoutProfileDescriptor>;
    try {
      logProfiles = deserializeLayoutProfileDescriptors(
        get(context, "studio.layout_profiles"),
      );
    } catch {
      logProfiles = [];
    }

    return {
      defaultProfileName,
      logProfiles,
    };
  });

  const dataStoreLayoutProfilesQuery = useDataStoreLayoutProfiles();

  const enabled =
    logContextQuery.isSuccess && !dataStoreLayoutProfilesQuery.isLoading;

  return useDsmQuery({
    queryKey: layoutProfileKeys.fetch(logContextQuery.data?.defaultProfileName),
    queryFn(): sdkTypes.FoundLayoutProfileDescriptor | null {
      invariant(enabled, "Query not enabled");

      const {
        data: { defaultProfileName, logProfiles },
      } = logContextQuery;

      if (defaultProfileName == null) {
        return null;
      }

      const profile = logProfiles.find(
        (profile) => profile.name === defaultProfileName,
      );

      if (profile != null) {
        return profile;
      }

      if (dataStoreLayoutProfilesQuery.isSuccess) {
        const profile = dataStoreLayoutProfilesQuery.data.find(
          (profile) => profile.name === defaultProfileName,
        );

        if (profile != null) {
          return profile;
        }
      }

      return null;
    },
    enabled,
  });
}

export function useCreateLayoutProfile() {
  const queryClient = useDsmQueryClient();

  return useDsmMutation({
    mutationFn: createLayoutProfile,
    onSuccess() {
      return queryClient.invalidateQueries(layoutProfileKeys.lists());
    },
  });
}

export function useDeleteLayoutProfile() {
  const queryClient = useDsmQueryClient();

  return useDsmMutation({
    mutationFn: deleteLayoutProfile,
    onSuccess() {
      return queryClient.invalidateQueries(layoutProfileKeys.lists());
    },
  });
}

export function useSetDefaultLogLayoutProfile(): UseMutationResult<
  LogDataResponse,
  unknown,
  { profile: LayoutProfileDescriptor }
> {
  const { logId } = usePlayerParams();

  const queryClient = useDsmQueryClient();

  const { logApi } = useDataStoreClients();

  return useDsmMutation({
    async mutationFn({ profile }) {
      invariant(logId != null, "Log ID is required");

      const { data: log } = await logApi.fetchLog({ logId });

      const updatedContext = mergeDefaultLayoutIntoContext(
        log.context,
        profile,
      );

      return logApi.updateLog({
        logId,
        logUpdateRequest: {
          context: updatedContext,
        },
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({
        queryKey: logKeys.lists(),
        type: "inactive",
      });
      queryClient.setQueryData<LogDataResponse>(logKeys.fetch(logId), response);
      return queryClient.invalidateQueries({ queryKey: logKeys.lists() });
    },
  });
}

function mergeDefaultLayoutIntoContext(
  context: object | null,
  profile: LayoutProfileDescriptor,
): object {
  return produce(context ?? {}, (draft: any) => {
    // Create the "studio" namespace if it doesn't exist yet.
    draft.studio ??= {};

    // Always overwrite an existing default layout profile if one is set.
    draft.studio.default_layout_profile = profile.name;

    // Create the list of layout profiles if they don't yet exist, then push
    // the new default profile onto the list.
    draft.studio.layout_profiles ??= [];

    // Drop any existing layout profile with the same name as the new default.
    draft.studio.layout_profiles = draft.studio.layout_profiles.filter(
      (existingProfile: any) => existingProfile.name !== profile.name,
    );
    draft.studio.layout_profiles.push(
      serializeLayoutProfileDescriptors(profile)[0],
    );
  });
}
