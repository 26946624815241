import React from "react";
import { Box, Button, Divider, Stack, styled, Typography } from "@mui/material";
import {
  ChartContainer,
  ChartsGrid,
  ChartsReferenceLine,
  ChartsXAxis,
  ScatterPlot,
} from "@mui/x-charts";
import { TimerSand } from "mdi-material-ui";
import useResizeObserver from "use-resize-observer";
import { Center } from "~/components/Center";
import { JsonTree } from "~/components/JsonTree";
import { ErrorMessage } from "~/components/error-message";
import { secondsToNanoseconds, utcToRelativeNanoseconds } from "~/lib/dates";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../../actions";
import { LoadingFeedback, PanelLayout } from "../../components";
import { useSkipToFirstTimestamp, useUpdatePanelBuffering } from "../../hooks";
import type { TimelinePanel } from "../../panels";
import { getPrimaryTopicDescriptor, VisualizationType } from "../../panels";
import {
  useFormatPlaybackTimestamp,
  useLoadedPlaybackSource,
} from "../../playback";
import type { PlayerRecord } from "../../record-store";
import { useRecords } from "../../record-store";
import { calculateRecordWindow } from "../../utils";
import { useVisualizationStoreParams } from "../context";
import { calculateWindowTicks } from "../utils";

const WINDOW_SIZE = secondsToNanoseconds(30);

export function TimelineVisualization({
  panel,
  topic,
}: {
  panel: TimelinePanel;
  topic: Topic;
}) {
  const { ref, width } = useResizeObserver();

  const storeParams = useVisualizationStoreParams(VisualizationType.Timeline);
  const { snapshot, isPlaceholder } = useRecords({
    recordType: "default",
    topic,
    descriptor: getPrimaryTopicDescriptor(panel),
    ...storeParams,
  });
  useUpdatePanelBuffering(snapshot.status === "pending" || isPlaceholder);

  const playerActions = usePlayerActions();

  useSkipToFirstTimestamp(panel, topic, snapshot.status === "fulfilled");

  let content;
  if (snapshot.status === "pending") {
    content = <LoadingFeedback description="data points" />;
  } else if (snapshot.status === "rejected") {
    content = (
      <ErrorMessage>An error occurred. Couldn't get timeline data</ErrorMessage>
    );
  } else {
    const currentRecord = snapshot.value.findLast(
      (record) => record.timestamp <= snapshot.request.timestamp,
    );

    let body: React.ReactNode;
    if (currentRecord == null) {
      const firstRecord = snapshot.value.at(0);

      body = (
        <Center>
          <TimerSand fontSize="large" />
          <Typography variant="h5" component="p" paragraph>
            No recent message
          </Typography>
          {firstRecord != null && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => playerActions.seek(firstRecord.timestamp)}
            >
              Skip to First Message
            </Button>
          )}
        </Center>
      );
    } else {
      body = (
        <Box sx={{ paddingInline: 2 }}>
          <JsonTree
            key={snapshot.request.descriptor.name}
            src={currentRecord.data}
          />
        </Box>
      );
    }

    content = width != null && (
      <Stack
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <TimelineChart
          width={width}
          records={snapshot.value}
          timestamp={snapshot.request.timestamp}
          currentRecord={currentRecord}
        />
        <Divider />
        <Box sx={{ flex: 1, minHeight: 0, overflowY: "auto" }}>{body}</Box>
        {isPlaceholder && <LoadingFeedback description="data points" />}
      </Stack>
    );
  }

  return <PanelLayout contentRef={ref}>{content}</PanelLayout>;
}

const G = styled("g")({
  "& circle": {
    stroke: "white",
    strokeWidth: 1,
  },
});

function TimelineChart({
  width,
  records,
  timestamp,
  currentRecord,
}: {
  width: number;
  records: ReadonlyArray<PlayerRecord<"default">>;
  timestamp: bigint;
  currentRecord: PlayerRecord<"default"> | undefined;
}) {
  const playbackSource = useLoadedPlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const recordWindow = calculateRecordWindow(
    WINDOW_SIZE,
    timestamp,
    playbackSource.bounds,
  );

  const scatterData = records.map((record) => ({
    x: utcToRelativeNanoseconds(
      record.timestamp,
      playbackSource.bounds.startTime,
    ),
    y: record.timestamp === currentRecord?.timestamp ? 0.8 : 0.4,
    id: String(record.timestamp),
  }));

  return (
    <ChartContainer
      width={width}
      height={85}
      xAxis={[
        {
          valueFormatter: formatPlaybackTimestamp,
          min: utcToRelativeNanoseconds(
            recordWindow.startTime,
            playbackSource.bounds.startTime,
          ),
          max: utcToRelativeNanoseconds(
            recordWindow.endTime,
            playbackSource.bounds.startTime,
          ),
          tickInterval: calculateWindowTicks(
            recordWindow,
            secondsToNanoseconds(5),
            WINDOW_SIZE,
            playbackSource.bounds.startTime,
          ),
        },
      ]}
      series={[
        {
          type: "scatter",
          data: scatterData,
          markerSize: 7,
        },
      ]}
      yAxis={[
        {
          min: 0,
          max: 1,
          colorMap: {
            type: "piecewise",
            thresholds: [0.8],
            colors: ["darkgrey", "tomato"],
          },
        },
      ]}
      margin={{
        top: 30,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <ChartsXAxis position="top" />
      <ChartsGrid vertical />
      <G>
        <ScatterPlot />
      </G>
      <ChartsReferenceLine
        x={utcToRelativeNanoseconds(timestamp, playbackSource.bounds.startTime)}
      />
    </ChartContainer>
  );
}
