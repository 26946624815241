import React, { useRef } from "react";
import type { ButtonBaseActions } from "@mui/material";
import {
  Box,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  styled,
  svgIconClasses,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import useResizeObserver from "use-resize-observer";
import { Center } from "~/components/Center";
import { Loading } from "~/components/Loading";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { assertCountableListResponse } from "~/domain/common";
import { useIsMobile } from "~/layout";
import type { ListLogsRequest, Log, LogListResponse } from "~/lqs";
import { useLogs } from "~/lqs";
import type { LabelProcessor, NoteFormRenderer } from "./log-gallery";
import { LogGalleryItem } from "./log-gallery";
import type { LogMarker } from "./utils";
import { calculateLogMarkers } from "./utils";

const LazyMapView = React.lazy(() => import("./lazy-map-view"));

const classNames = {
  controlsContainer: "controls-container",
  controlsContent: "controls-content",
  results: "results",
} as const;

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  [`& .${classNames.controlsContainer}`]: {
    position: "absolute",
    inset: `2px ${theme.spacing(1)} ${theme.spacing(1)}`,
    padding: theme.spacing(1),
    pointerEvents: "none",
  },
  [`& .${classNames.controlsContent}`]: {
    width: 450,
    // Map controls in top right corner are 29px wide
    maxWidth: "calc(100% - 29px)",
    maxHeight: "100%",
    padding: theme.spacing(2),
    pointerEvents: "auto",
  },
  [`& .${classNames.results}`]: {
    minHeight: 0,
    overflowY: "auto",
    padding: theme.spacing(1),
    // Focusing the list item when the user clicks a log marker
    // should scroll smoothly
    scrollBehavior: "smooth",
    '&[data-mobile="false"]': {
      // Counteract gap spacing
      marginBlock: theme.spacing(-3),
    },
    "& > *:not(:last-of-type)": {
      marginBlockEnd: theme.spacing(2),
    },
  },
  [`& .maplibregl-marker .${svgIconClasses.root}`]: {
    cursor: "pointer",
    scale: "1",
    color: theme.palette.error.main,
    transition: theme.transitions.create(["scale", "color"]),
    '&[data-active="true"]': {
      scale: "1.15",
      color: theme.palette.error.dark,
    },
  },
}));

export type MapFiltersRenderer = (params: {
  results: React.ReactNode;
}) => React.ReactNode;

export interface LogMapResponse {
  count: number;
  logs: ReadonlyArray<Log>;
  logMarkers: ReadonlyArray<LogMarker>;
}

export function LogMap({
  logsQuery,
  renderNoteForm,
  processLabels,
  renderFilters,
  pagination,
  autoZoom = true,
}: {
  logsQuery: UseQueryResult<LogMapResponse>;
  renderNoteForm?: NoteFormRenderer;
  processLabels: LabelProcessor;
  renderFilters: MapFiltersRenderer;
  pagination: React.ReactNode;
  autoZoom?: boolean;
}) {
  const { ref, width, height } = useResizeObserver();

  const { makeRefCallback, focusLog } = useLogFocus();

  const isMobile = useIsMobile();

  const results = (
    <>
      <Box sx={{ flex: "none", position: "relative", mt: 1.5 }}>
        <Divider />
        {logsQuery.isRefetching && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </Box>
      <QueryRenderer
        query={logsQuery}
        loading={<Loading type="circular" />}
        error={<ErrorMessage>Error fetching logs</ErrorMessage>}
        success={({ logs }) => (
          <div
            data-mobile={isMobile}
            className={classNames.results}
            // Used for thumbnail cards' IntersectionObservers
            data-scroll-root=""
          >
            {logs.length === 0 ? (
              <Center sx={{ my: 4 }}>
                <Typography variant="h5" component="p">
                  No logs
                </Typography>
              </Center>
            ) : (
              logs.map((log) => (
                <LogGalleryItem
                  key={log.id}
                  log={log}
                  renderNoteForm={renderNoteForm}
                  processLabels={processLabels}
                  actionRef={makeRefCallback(log.id)}
                />
              ))
            )}
          </div>
        )}
      />
      {pagination && (
        <>
          <Divider sx={{ mb: isMobile ? 3 : 0 }} />
          {pagination}
        </>
      )}
    </>
  );

  return (
    <Root ref={ref}>
      {logsQuery.isSuccess && (
        <ErrorBoundary
          fallback={
            <Center>
              <Typography variant="h5" component="p">
                Error initializing map
              </Typography>
            </Center>
          }
        >
          <React.Suspense fallback={null}>
            <LazyMapView
              width={width}
              height={height}
              logMarkers={logsQuery.data.logMarkers}
              onLogClick={focusLog}
              autoZoom={autoZoom}
            />
          </React.Suspense>
        </ErrorBoundary>
      )}
      <div className={classNames.controlsContainer}>
        <Paper
          className={classNames.controlsContent}
          component={Stack}
          spacing={isMobile ? 0 : 3}
        >
          {renderFilters({ results })}
        </Paper>
      </div>
    </Root>
  );
}

function useLogFocus() {
  const mapRef = useRef(new Map<Log["id"], ButtonBaseActions>());

  return {
    makeRefCallback(logId: Log["id"]): React.RefCallback<ButtonBaseActions> {
      return function refCallback(actions) {
        if (actions === null) {
          mapRef.current.delete(logId);
        } else {
          mapRef.current.set(logId, actions);
        }
      };
    },
    focusLog(logId: Log["id"]) {
      mapRef.current.get(logId)?.focusVisible();
    },
  };
}

export function useLogMapQuery(
  request: ListLogsRequest,
): UseQueryResult<LogMapResponse> {
  return useLogs(
    {
      ...request,
      startTimeNull: false,
      endTimeNull: false,
      includeCount: true,
      contextFilter: JSON.stringify([
        {
          var: "sample_coordinates.longitude",
          op: "gt",
          val: -180,
        },
      ]),
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectLogMapResponse,
    },
  );
}

function selectLogMapResponse(response: LogListResponse): LogMapResponse {
  assertCountableListResponse(response);

  return {
    count: response.count,
    logs: response.data,
    logMarkers: calculateLogMarkers(response.data),
  };
}
