import React from "react";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils/assertNever";
import { usePlayerActions } from "../../actions";
import { SourceTopicSelect, TopicSelect } from "../../components";
import { useLoadedPlayerTopics } from "../../hooks";
import type { MapPanel } from "../../panels";
import { getMapSubVisualization } from "../../panels";
import {
  filterSupplementaryTopics,
  findSelectedSupplementaryTopic,
} from "./utils";

export function MapVisualizationControls({
  panel,
  topic,
}: {
  panel: MapPanel;
  topic: Topic;
}) {
  const subVisualization = getMapSubVisualization(topic.typeName);

  let controls;
  switch (subVisualization) {
    case "gps": {
      controls = <GpsVisualizationControls panel={panel} topic={topic} />;

      break;
    }
    case "cartesian": {
      controls = null;

      break;
    }
    default: {
      assertNever(subVisualization);
    }
  }

  return (
    <>
      <SourceTopicSelect
        panel={panel}
        topic={topic}
        sx={{ marginBlockEnd: 2 }}
      />
      {controls}
    </>
  );
}

function GpsVisualizationControls({
  panel,
  topic,
}: {
  panel: MapPanel;
  topic: Topic;
}) {
  const playerActions = usePlayerActions();

  const topics = useLoadedPlayerTopics();

  const supplementaryTopics = filterSupplementaryTopics(topic, topics);

  const selectedSupplementaryTopic = findSelectedSupplementaryTopic(
    supplementaryTopics,
    panel,
  );

  function handleSupplementaryTopicChange(newValue: Topic | null): void {
    if (newValue === null) {
      playerActions.removeSupplementaryMapTopic(panel);
    } else {
      playerActions.addSupplementaryMapTopic(panel, newValue);
    }
  }

  return (
    <TopicSelect
      inputLabel="Comparison topic"
      value={selectedSupplementaryTopic}
      topics={supplementaryTopics}
      onChange={handleSupplementaryTopicChange}
    />
  );
}
