import type { SxProps } from "@mui/material";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../actions";
import { useLoadedPlayerTopics } from "../hooks";
import type { InitializedPanel } from "../panels";
import { supportsVisualization } from "../panels";
import { TopicSelect } from "./topic-select";

export function SourceTopicSelect({
  panel,
  topic,
  sx,
}: {
  panel: InitializedPanel;
  topic: Topic;
  sx?: SxProps;
}) {
  const playerTopics = useLoadedPlayerTopics();

  const playerActions = usePlayerActions();

  function handleTopicChange(newTopic: Topic): void {
    playerActions.changeTopic(panel, newTopic);
  }

  const availableTopics = playerTopics.filter((playerTopic) =>
    supportsVisualization(playerTopic.typeName, panel.visualization),
  );

  return (
    <TopicSelect
      inputLabel="Source topic"
      topics={availableTopics}
      disableClearing
      value={topic}
      onChange={handleTopicChange}
      sx={sx}
    />
  );
}
