import { useCallback } from "react";
import type { TaggingMode } from "~/domain/logs";
import type { Topic } from "~/lqs";
import type { TimeRange } from "~/types";
import { usePlayerConfig } from "./config";
import type { PointerLocation } from "./hooks";
import { useControlledPanelContext } from "./layout";
import type {
  ChartPanel,
  ChartTopicDescriptor,
  FlipDirection,
  ImagePanel,
  ImagePanelWithInference,
  InitializedPanel,
  LayoutProfileDescriptor,
  MapPanel,
  Panel,
  RotationDirection,
  SplitOrientation,
  ThreeDPanel,
  TopicSelectionConfig,
  UninitializedPanel,
  VisualizationFilter,
  VisualizationType,
} from "./panels";
import * as panels from "./panels";
import * as playback from "./playback";
import { useTagActions } from "./tags";
import type { PlaybackSpeedValue, TimestepValue } from "./types";

// This hook provides handlers for responding to most actions in the Player.
// Many of these actions just wrap the underlying dispatch or state-setting
// call but some of these require state updates across several providers.
// Rather than spreading the logic around the Player, this hook encapsulates it
// and exposes a simple function per action for what's happening from the
// consumer's perspective.
// Were the Player to switch to a zustand store or even just one big React
// reducer for all the related state, this hook would nicely encapsulate that
// change and should let consumers continue using these actions as-is.
export function usePlayerActions() {
  const { autoSkip } = usePlayerConfig();

  const { dispatch: panelLayoutDispatch } = panels.usePanelLayoutContext();

  const { dispatch: playbackSourceDispatch, inRangeMode } =
    playback.usePlaybackSource();

  const playbackSettings = playback.usePlaybackSettings();

  const controlledPanelContext = useControlledPanelContext({ strict: false });

  const tagActions = useTagActions();

  return {
    loadLayout(profile: LayoutProfileDescriptor): void {
      panelLayoutDispatch(panels.loadLayout({ profile }));
      controlledPanelContext?.handleLoadLayout();
    },
    // Used in a memoized callback passed to <TopicTree />
    selectPanelTopic: useCallback(
      (panelId: UninitializedPanel["id"], topic: Topic): void => {
        panelLayoutDispatch(panels.selectTopic({ panelId, topic }));
      },
      [panelLayoutDispatch],
    ),
    changeTopic(panel: InitializedPanel, topic: Topic): void {
      panelLayoutDispatch(panels.changeTopic({ panelId: panel.id, topic }));
    },
    chooseVisualization(panel: Panel, visualization: VisualizationType): void {
      panelLayoutDispatch(
        panels.chooseVisualization({ panelId: panel.id, tab: visualization }),
      );
    },
    clearPanelTopic(panel: InitializedPanel): void {
      panelLayoutDispatch(panels.chooseNewTopic({ panelId: panel.id }));
      controlledPanelContext?.handleClearPanelTopic(panel);
    },
    closePanel(panel: Panel): void {
      panelLayoutDispatch(panels.removePanel({ panelId: panel.id }));
      controlledPanelContext?.handleClosePanel(panel);
    },
    togglePanelControls(panel: Panel): void {
      controlledPanelContext?.togglePanelControls(panel);
    },
    splitPanel(panel: Panel, orientation: SplitOrientation): void {
      panelLayoutDispatch(
        panels.splitPanel({ panelId: panel.id, orientation }),
      );
    },
    setTopicSearchFilter(
      panel: UninitializedPanel,
      search: TopicSelectionConfig["search"],
    ): void {
      panelLayoutDispatch(
        panels.setTopicSearchFilter({ panelId: panel.id, search }),
      );
    },
    setTopicVisualizationFilter(
      panel: UninitializedPanel,
      filterName: VisualizationFilter | null,
    ): void {
      panelLayoutDispatch(
        panels.setTopicVisualizationFilter({
          panelId: panel.id,
          filterName,
        }),
      );
    },
    adjustImageBrightness(panel: ImagePanel, brightness: number): void {
      panelLayoutDispatch(
        panels.adjustImageBrightness({ panelId: panel.id, brightness }),
      );
    },
    adjustImageContrast(panel: ImagePanel, contrast: number): void {
      panelLayoutDispatch(
        panels.adjustImageContrast({ panelId: panel.id, contrast }),
      );
    },
    toggleImageColorization(panel: ImagePanel, colorize: boolean): void {
      panelLayoutDispatch(
        panels.toggleImageColorization({ panelId: panel.id, colorize }),
      );
    },
    rotateImage(panel: ImagePanel, direction: RotationDirection): void {
      panelLayoutDispatch(panels.rotateImage({ panelId: panel.id, direction }));
    },
    flipImage(panel: ImagePanel, flipDirection: FlipDirection | null): void {
      panelLayoutDispatch(
        panels.setImageFlipDirection({
          panelId: panel.id,
          flipDirection,
        }),
      );
    },
    setInferenceTopic(panel: ImagePanel, topic: Topic | null): void {
      panelLayoutDispatch(
        panels.setInferenceTopic({ panelId: panel.id, inferenceTopic: topic }),
      );
    },
    toggleInferenceTransformLock(
      panel: ImagePanelWithInference,
      lock: boolean,
    ): void {
      panelLayoutDispatch(
        panels.toggleInferenceTransformLock({ panelId: panel.id, lock }),
      );
    },
    toggleInferenceBoundingBoxes(
      panel: ImagePanelWithInference,
      show: boolean,
    ): void {
      panelLayoutDispatch(
        panels.showDetectionBoundingBoxes({
          panelId: panel.id,
          showDetectionBoundingBoxes: show,
        }),
      );
    },
    toggleInferenceClassNames(
      panel: ImagePanelWithInference,
      show: boolean,
    ): void {
      panelLayoutDispatch(
        panels.showDetectionClassNames({
          panelId: panel.id,
          showDetectionClassNames: show,
        }),
      );
    },
    toggleObjectClass(
      panel: ImagePanelWithInference,
      className: string,
      show: boolean,
    ): void {
      panelLayoutDispatch(
        panels.changeObjectClassVisibility({
          panelId: panel.id,
          className,
          hideClass: !show,
        }),
      );
    },
    setInferenceImageOpacity(
      panel: ImagePanelWithInference,
      opacity: number,
    ): void {
      panelLayoutDispatch(
        panels.setInferenceImageOpacity({ panelId: panel.id, opacity }),
      );
    },
    toggleInferenceImageColorization(
      panel: ImagePanelWithInference,
      colorize: boolean,
    ): void {
      panelLayoutDispatch(
        panels.toggleInferenceImageColorization({
          panelId: panel.id,
          colorize,
        }),
      );
    },
    clipInferenceImage(
      panel: ImagePanelWithInference,
      pointerLocation: PointerLocation,
      baseImageDimensions: { naturalWidth: number; naturalHeight: number },
    ): void {
      panelLayoutDispatch(
        panels.setInferenceImageClipInset({
          panelId: panel.id,
          pointerLocation,
          baseImageDimensions,
        }),
      );
    },
    addChartField(panel: ChartPanel, field: string, data: object | null): void {
      panelLayoutDispatch(
        panels.addChartField({ panelId: panel.id, field, data }),
      );
    },
    removeChartField(panel: ChartPanel, field: string): void {
      panelLayoutDispatch(
        panels.removeChartField({ panelId: panel.id, field }),
      );
    },
    toggleOverviewChart(panel: ChartPanel, value: boolean): void {
      panelLayoutDispatch(
        panels.toggleOverviewChart({ panelId: panel.id, value }),
      );
    },
    toggleFieldControls(
      panel: ChartPanel,
      name: ChartTopicDescriptor["name"],
    ): void {
      panelLayoutDispatch(
        panels.toggleFieldControls({ panelId: panel.id, name }),
      );
    },
    setPointCloudPointSize(panel: ThreeDPanel, size: number): void {
      panelLayoutDispatch(
        panels.setPointCloudPointSize({ panelId: panel.id, pointSize: size }),
      );
    },
    selectTag(panel: InitializedPanel, tag: string): void {
      panelLayoutDispatch(panels.selectTag({ panelId: panel.id, tag }));
    },
    addSupplementaryMapTopic(panel: MapPanel, topic: Topic): void {
      panelLayoutDispatch(
        panels.setSupplementaryMapTopic({
          panelId: panel.id,
          topic,
        }),
      );
    },
    removeSupplementaryMapTopic(panel: MapPanel): void {
      panelLayoutDispatch(
        panels.clearSupplementaryMapTopic({ panelId: panel.id }),
      );
    },
    setPlaybackSpeed(speed: PlaybackSpeedValue): void {
      playbackSettings.setSpeed(speed);
    },
    setPlaybackTimestep(timestep: TimestepValue): void {
      playbackSettings.setTimestep(timestep);
    },
    play(): void {
      playbackSourceDispatch(playback.play());
    },
    pause(): void {
      playbackSourceDispatch(playback.pause());
    },
    restart(): void {
      playbackSourceDispatch(playback.restart());
    },
    previousFrame(): void {
      playbackSourceDispatch(playback.previousFrame());
    },
    nextFrame(): void {
      playbackSourceDispatch(playback.nextFrame());
    },
    autoSkipToFirstTimestamp: useCallback(
      (panel: InitializedPanel, firstTimestamp: bigint) => {
        if (!autoSkip) {
          return;
        }

        if (panel.hasAutoSkipped) {
          return;
        }

        panelLayoutDispatch(
          panels.recordAutoSkip({
            panelId: panel.id,
          }),
        );
        playbackSourceDispatch(
          playback.autoSkipToFirstTimestamp({
            firstTimestamp,
          }),
        );
      },
      [autoSkip, panelLayoutDispatch, playbackSourceDispatch],
    ),
    seek(to: bigint): void {
      playbackSourceDispatch(playback.seek({ to }));
    },
    // Used in an Effect
    tick: useCallback(() => {
      playbackSourceDispatch(playback.tick());
    }, [playbackSourceDispatch]),
    enterPlaybackRangeMode(): void {
      playbackSourceDispatch(playback.enterRangeMode());
    },
    // Used in a memoized callback passed to <TopicTree />
    exitPlaybackRangeMode: useCallback((): void => {
      playbackSourceDispatch(playback.exitRangeMode());
    }, [playbackSourceDispatch]),
    setRange(range: TimeRange): void {
      playbackSourceDispatch(playback.setRange({ range }));
    },
    changeTaggingMode(newTaggingMode: TaggingMode): void {
      if (newTaggingMode === "range" && !inRangeMode) {
        playbackSourceDispatch(playback.enterRangeMode());
      } else if (newTaggingMode !== "range" && inRangeMode) {
        playbackSourceDispatch(playback.exitRangeMode());
      }

      tagActions.changeTaggingMode(newTaggingMode);
    },
    hideDefaultProfileVersionWarning(): void {
      panelLayoutDispatch(panels.hideDefaultProfileVersionWarning());
    },
  };
}
