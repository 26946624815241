import { useId } from "react";
import { useBlobSource } from "~/hooks";
import type { InferenceTopicDescriptor } from "../../panels";
import { getTransformProps } from "../../visualizations/image-visualization/utils";
import type { ImageSegmentationInferenceResults, Segmentation } from "./types";

export function ImageSegmentationsResultsVisualization({
  inferenceTopicDescriptor,
  results,
}: {
  inferenceTopicDescriptor: InferenceTopicDescriptor;
  results: ImageSegmentationInferenceResults;
}) {
  return results.segmentations.map((segmentation, index) => (
    <MaskImage
      // Multiple segmentations could share the same label and score if the
      // model performed instance segmentation. Aside from hashing the mask
      // (which is probably overkill), the only thing to possibly use as a
      // key is the index. This *should* be alright here since the <MaskImage />
      // only uses the useId hook for the SVG <filter> it encapsulates and
      // the useBlobSource hook which will clean up after itself when the Blob
      // changes.
      key={index}
      inferenceTopicDescriptor={inferenceTopicDescriptor}
      segmentation={segmentation}
    />
  ));
}

function MaskImage({
  inferenceTopicDescriptor,
  segmentation,
}: {
  inferenceTopicDescriptor: InferenceTopicDescriptor;
  segmentation: Segmentation;
}) {
  const filterId = useId();

  const [r, g, b] = segmentation.color;

  const { rotationDeg, flip, opacity } = inferenceTopicDescriptor;

  const ref = useBlobSource(segmentation.mask);

  // <feColorMatrix> docs found here: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feColorMatrix
  // The goal of this color matrix is for all pixels from the mask which are in
  // the segmentation to have the computed color applied and be fully opaque
  // while all other pixels should be completely transparent. The mask is
  // expected to be a grayscale image where every pixel in the segmentation is
  // white while all others are black and all pixels are fully opaque. To
  // convert the mask to how Studio wants to display it, a couple things need to
  // happen:
  // 1. Apply the computed color to every pixel.
  // 2. Make pixels not in the segmentation fully transparent.
  // Using the explanation from MDN:
  // 1. Set the computed R, G, and B values to the r5, g5, and b5 matrix
  //    elements, respectively, and set all other r*, g*, and b* elements to 0.
  //    r5, g5, and b5 are the only terms not dependent on the mask pixel's
  //    terms.
  // 2. Set a1 to 1 and all other a* elements to 0. If the mask pixel was white,
  //    its normalized R would be 1, so an a1 of 1 leads to A' of 1, i.e. fully
  //    opaque; otherwise, the mask pixel was black, giving it a normalized R of
  //    0, so A' will be 0, i.e. fully transparent. a1 is somewhat arbitrary
  //    since a2 and a3 also could've worked since they'd be multiplied against
  //    the normalized G and B, respectively, but one had to be chosen so I'm
  //    going with a1.
  const colorMatrix = `
    0 0 0 0 ${r / 255}
    0 0 0 0 ${g / 255}
    0 0 0 0 ${b / 255}
    1 0 0 0 0
  `;

  return (
    <>
      <img
        ref={ref}
        {...getTransformProps({
          rotationDeg,
          flipDirection: flip,
          filter: `url(#${filterId})`,
          style: {
            opacity,
          },
        })}
      />
      <svg style={{ display: "none" }}>
        <filter id={filterId}>
          <feColorMatrix type="matrix" values={colorMatrix} />
        </filter>
      </svg>
    </>
  );
}
