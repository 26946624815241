import type {
  FoundLayoutProfileDescriptor,
  FutureLayoutProfileDescriptor,
  LayoutProfileDescriptor,
} from "./types";

export function checkIsFutureLayoutProfileDescriptor(
  descriptor: FoundLayoutProfileDescriptor,
): descriptor is FutureLayoutProfileDescriptor {
  return !("layout" in descriptor);
}

export function checkIsLayoutProfileDescriptor(
  descriptor: FoundLayoutProfileDescriptor,
): descriptor is LayoutProfileDescriptor {
  return "layout" in descriptor;
}
