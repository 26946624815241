import React from "react";
import { Warning } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import { usePlayerActions } from "../actions";
import { usePlayerLog, usePlayerTopics } from "../hooks";
import { Panels, usePanelContext, usePanelLayoutContext } from "../panels";
import { VisualizationSwitch } from "../visualizations";

export function TopicSection() {
  const logQuery = usePlayerLog();

  const topicsQuery = usePlayerTopics();

  return (
    <Box sx={{ flex: 1, minHeight: 0 }}>
      {!logQuery.isSuccess || !topicsQuery.isSuccess ? (
        <Loading type="circular">
          <Typography variant="h6" component="p">
            Loading...
          </Typography>
        </Loading>
      ) : (
        <Panels
          element={
            <ErrorBoundary fallback={<PanelErrorFallback />}>
              <VisualizationSwitch topics={topicsQuery.data} />
            </ErrorBoundary>
          }
        />
      )}
      <FutureDefaultLayoutProfileVersionWarning />
    </Box>
  );
}

function PanelErrorFallback() {
  const panel = usePanelContext();

  const playerActions = usePlayerActions();

  const { resetBoundary } = useErrorBoundary();

  function handleCloseOnError() {
    playerActions.closePanel(panel);
    resetBoundary();
  }

  return (
    <ErrorMessage disableTypography>
      <ErrorMessage.Paragraph>
        Unexpected error visualizing data
      </ErrorMessage.Paragraph>
      <Button color="primary" variant="text" onClick={handleCloseOnError}>
        Close panel
      </Button>
    </ErrorMessage>
  );
}

function FutureDefaultLayoutProfileVersionWarning() {
  const { showFutureDefaultProfileWarning } = usePanelLayoutContext();

  const playerActions = usePlayerActions();

  if (!showFutureDefaultProfileWarning) {
    return null;
  }

  function handleRefresh(): void {
    window.location.reload();
  }

  function handleHideWarning(): void {
    playerActions.hideDefaultProfileVersionWarning();
  }

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      aria-labelledby="profile-warning-title"
    >
      <DialogTitle
        id="profile-warning-title"
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
      >
        <Warning color="warning" />
        Newer Version of Studio
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          This log's default layout profile appears to have been made in a newer
          version of Studio.
        </Typography>
        <Typography>
          To use the newest version of Studio and see this log's default layout
          profile, refresh your browser.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="text" onClick={handleHideWarning}>
          Skip for Now
        </Button>
        <Button color="primary" variant="outlined" onClick={handleRefresh}>
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
}
