import { hashQueryKey } from "@tanstack/react-query";
import type { MarkRequired, StrictOmit } from "ts-essentials";
import type { OverrideProperties, RequireExactlyOne } from "type-fest";
import { invariant } from "~/lib/invariant";
import { emplace, sortedLastIndexBy } from "~/lib/std";
import type { ListRecordsRequest, RecordListResponse, Topic } from "~/lqs";
import { circumventPagination } from "~/lqs";
import type { TimeRange } from "~/types";
import type { DataFilter } from "../types";
import { NoRecordsError } from "./errors";
import { FetchLoopError, LoopDetector } from "./loop-detector";
import type {
  InitializableRecordTypeHandler,
  RecordTypeHandler,
} from "./record-types";
import { createRecordHandler, isInitializableHandler } from "./record-types";
import type {
  AllRecordsRequest,
  AllRecordsTopicSubscription,
  LimitedRecordsTopicSubscription,
  ListRecordsFn,
  PlayerRecord,
  RecordsRequest,
  RecordsResponse,
  RecordType,
  TopicSubscription,
} from "./types";

type BoundedTopicSubscription<TRecordType extends RecordType> =
  TopicSubscription<TRecordType> & {
    topicStartTime: bigint;
    topicEndTime: bigint;
  };

type CacheEntryIdentifier = Pick<
  RecordsRequest<RecordType>,
  "topicId" | "frequency" | "recordType" | "all"
> & {
  queryDataFilter?: DataFilter | ReadonlyArray<DataFilter>;
};

interface RecordSequenceParameters<TRecordType extends RecordType> {
  records: ReadonlyArray<PlayerRecord<TRecordType>>;
  hasFirstTopicRecord?: boolean;
  hasLastTopicRecord?: boolean;
}

class RecordSequence<TRecordType extends RecordType> implements TimeRange {
  readonly records: ReadonlyArray<PlayerRecord<TRecordType>>;

  readonly startTime: bigint;
  hasFirstTopicRecord: boolean;
  readonly endTime: bigint;
  hasLastTopicRecord: boolean;

  constructor({
    records,
    hasFirstTopicRecord = false,
    hasLastTopicRecord = false,
  }: RecordSequenceParameters<TRecordType>) {
    invariant(records.length > 0, "Sequence must have at least one record");

    this.records = records;
    this.startTime = records.at(0)!.timestamp;
    this.hasFirstTopicRecord = hasFirstTopicRecord;
    this.endTime = records.at(-1)!.timestamp;
    this.hasLastTopicRecord = hasLastTopicRecord;
  }

  static from<TRecordType extends RecordType>(
    ...sequences: ReadonlyArray<RecordSequence<TRecordType> | null>
  ): RecordSequence<TRecordType> {
    // Since records can be inserted at any time, it's possible for a record
    // to appear in 2 different sibling sequences depending on when the back-end
    // started handling each request and when the record was inserted. Rather
    // than being treated as an error, the duplicate should just be dropped.
    const uniqueRecords = new Array<PlayerRecord<TRecordType>>();
    sequences.forEach((sequence) => {
      sequence?.records.forEach((currentRecord) => {
        const lastRecord = uniqueRecords.at(-1);
        if (
          lastRecord === undefined ||
          currentRecord.timestamp > lastRecord.timestamp
        ) {
          uniqueRecords.push(currentRecord);
        }
      });
    });

    return new RecordSequence({
      records: uniqueRecords,
      hasFirstTopicRecord: sequences.some(
        (sequence) => sequence?.hasFirstTopicRecord,
      ),
      hasLastTopicRecord: sequences.some(
        (sequence) => sequence?.hasLastTopicRecord,
      ),
    });
  }

  canHandleTimestamp(timestamp: bigint): boolean {
    return (
      (this.startTime <= timestamp && timestamp <= this.endTime) ||
      (this.hasFirstTopicRecord && timestamp < this.startTime) ||
      (this.hasLastTopicRecord && timestamp > this.endTime)
    );
  }

  compareTo(sequence: RecordSequence<TRecordType>): number {
    // For any two sequences, one will always be entirely prior to the other.
    return this.startTime < sequence.startTime ? -1 : 1;
  }

  calculateRemainingRecords(request: {
    timestamp: bigint;
    countBehind: number;
    countAhead: number;
  }): {
    behind: number;
    ahead: number;
  } {
    const availableRecords = this.#calculateAvailableRecords(request.timestamp);

    const initialBehind = Math.max(
      request.countBehind - availableRecords.behind,
      0,
    );
    const initialAhead = Math.max(
      request.countAhead - availableRecords.ahead,
      0,
    );

    let behind: number;
    let ahead: number;
    if (this.hasFirstTopicRecord && this.hasLastTopicRecord) {
      // Doesn't matter whether more needed to be fetched or not since all
      // available records are in this sequence.
      behind = 0;
      ahead = 0;
    } else if (this.hasFirstTopicRecord) {
      // No more records to fetch behind this sequence's start time but however
      // many were initially needed to be fetched behind should instead be
      // fetched ahead.
      behind = 0;
      ahead = Math.max(
        request.countAhead + initialBehind - availableRecords.ahead,
        0,
      );
    } else if (this.hasLastTopicRecord) {
      // No more records to fetch ahead of this sequence's end time but however
      // many were initially needed to be fetched ahead should instead be
      // fetched behind.
      behind = Math.max(
        request.countBehind + initialAhead - availableRecords.behind,
        0,
      );
      ahead = 0;
    } else {
      // Fetch records in both directions if necessary.
      behind = initialBehind;
      ahead = initialAhead;
    }

    return { behind, ahead };
  }

  getRecords(
    request: RecordsRequest<TRecordType>,
  ): Array<PlayerRecord<TRecordType>> | null {
    if (checkIsAllRecordsRequest(request)) {
      return this.records.slice();
    }

    if (!this.canHandleTimestamp(request.timestamp)) {
      return null;
    }

    // `request.count` could be odd in which case the lower slice of records
    // will get one more than the upper slice.
    const countBehind = Math.ceil(request.count / 2);
    const countAhead = request.count - countBehind;

    const availableRecords = this.#calculateAvailableRecords(request.timestamp);

    if (this.records.length < request.count) {
      if (this.hasFirstTopicRecord && this.hasLastTopicRecord) {
        return this.records.slice();
      } else {
        return null;
      }
    } else if (availableRecords.behind < countBehind) {
      if (
        this.hasFirstTopicRecord &&
        availableRecords.ahead >=
          countAhead + (countBehind - availableRecords.behind)
      ) {
        return this.records.slice(0, request.count);
      } else {
        return null;
      }
    } else if (availableRecords.ahead < countAhead) {
      if (
        this.hasLastTopicRecord &&
        availableRecords.behind >=
          countBehind + (countAhead - availableRecords.ahead)
      ) {
        return this.records.slice(
          this.records.length - request.count,
          this.records.length,
        );
      } else {
        return null;
      }
    } else {
      return this.records.slice(
        availableRecords.index + 1 - countBehind,
        availableRecords.index + 1 + countAhead,
      );
    }
  }

  #calculateAvailableRecords(timestamp: bigint): {
    index: number;
    behind: number;
    ahead: number;
  } {
    const mostRecentRecordIndex = this.#getMostRecentRecordIndex(timestamp);

    return {
      index: mostRecentRecordIndex,
      // Most recent record is included in the records available at or behind
      // the timestamp
      behind: mostRecentRecordIndex + 1,
      ahead: this.records.length - mostRecentRecordIndex - 1,
    };
  }

  #getMostRecentRecordIndex(timestamp: bigint): number {
    // `sortedLastIndexBy` returns the last index where `timestamp` could be
    // inserted while maintaining sort order, meaning the index of the most
    // recent record (<= `timestamp`) is the previous index. Could be -1 if
    // `timestamp` is less than earliest record in this sequence.
    return (
      sortedLastIndexBy(
        this.records,
        { timestamp } as PlayerRecord,
        "timestamp",
      ) - 1
    );
  }
}

async function processFetchedRecords<TRecordType extends RecordType>(
  handler: RecordTypeHandler<TRecordType>,
  response: RecordListResponse,
): Promise<PlayerRecordListResponse<TRecordType>> {
  return {
    ...response,
    data: await Promise.all(
      response.data.map(async (record): Promise<PlayerRecord<TRecordType>> => {
        const data = await handler.transform(record);

        return {
          timestamp: record.timestamp,
          topicId: record.topicId,
          context: record.context,
          data,
        };
      }),
    ),
  };
}

type RequestParameters = Pick<ListRecordsRequest, "offset" | "sort"> &
  RequireExactlyOne<{
    timestampGt: bigint;
    timestampGte: bigint;
  }> &
  RequireExactlyOne<{
    timestampLt: bigint;
    timestampLte: bigint;
  }>;

type PlayerRecordListResponse<TRecordType extends RecordType> =
  OverrideProperties<
    RecordListResponse,
    {
      data: Array<PlayerRecord<TRecordType>>;
    }
  >;

interface RequestTrackerParameters<TRecordType extends RecordType> {
  topicId: Topic["id"];
  frequency: number | null;
  limit: number;
  handler: RecordTypeHandler<TRecordType>;
  requestParameters: RequestParameters;
  listRecords: ListRecordsFn;
  onSuccess: (
    tracker: RequestTracker<TRecordType>,
    response: PlayerRecordListResponse<TRecordType>,
  ) => void;
  onError: (tracker: RequestTracker<TRecordType>, error: unknown) => void;
}

class RequestTracker<TRecordType extends RecordType> {
  noPriorRecords = false;
  previousSequenceEndTime: bigint | null = null;
  noSubsequentRecords = false;
  nextSequenceStartTime: bigint | null = null;

  previousSibling: RequestTracker<TRecordType> | null = null;
  nextSibling: RequestTracker<TRecordType> | null = null;

  #response: PlayerRecordListResponse<TRecordType> | null = null;

  readonly #topicId: Topic["id"];
  readonly #frequency: number | null;
  readonly #limit: number;
  readonly #requestParameters: RequestParameters;

  readonly #handler: RecordTypeHandler<TRecordType>;

  readonly #listRecords: ListRecordsFn;
  readonly #controller = new AbortController();

  constructor({
    topicId,
    frequency,
    limit,
    handler,
    requestParameters,
    listRecords,
    onSuccess,
    onError,
  }: RequestTrackerParameters<TRecordType>) {
    this.#topicId = topicId;
    this.#frequency = frequency;
    this.#limit = limit;
    this.#requestParameters = requestParameters;

    this.#handler = handler;

    this.#listRecords = listRecords;

    this.#executeRequest({ onSuccess, onError });
  }

  /**
   * Returns `true` if this tracked request could possibly contain records
   * that could also be contained by a request defined by `requestParameters.`
   * If it's possible for both to contain at least one common record, the
   * request defined by `requestParameters` should not be made to avoid
   * duplication.
   */
  blocks(requestParameters: RequestParameters): boolean {
    const thisTimeRange = toClosedInterval(this.#requestParameters);
    const otherTimeRange = toClosedInterval(requestParameters);

    return doIntervalsOverlap(thisTimeRange, otherTimeRange);
  }

  effectivelyContains(
    sequence: RecordSequence<TRecordType>,
    timestamp: bigint,
  ): boolean {
    const thisTimeRange = toClosedInterval(this.#requestParameters);

    if (this.previousSequenceEndTime === sequence.endTime) {
      return (
        sequence.endTime <= timestamp && timestamp <= thisTimeRange.startTime
      );
    } else if (this.nextSequenceStartTime === sequence.startTime) {
      return (
        thisTimeRange.endTime <= timestamp && timestamp <= sequence.startTime
      );
    } else {
      return false;
    }
  }

  getPreviousSequence(
    sequences: ReadonlyArray<RecordSequence<TRecordType>>,
  ): RecordSequence<TRecordType> | null {
    this.#requireResolvedResponse();

    let previousSequence: RecordSequence<TRecordType> | null = null;
    if (this.previousSequenceEndTime !== null) {
      // At some earlier point, this tracker's previous sibling's request
      // finished with at least one record. Since this tracker's records are
      // guaranteed to be adjacent to that sibling's records, this tracker can
      // be merged directly with its sibling's sequence.
      previousSequence = sequences.find(
        (sequence) => sequence.endTime === this.previousSequenceEndTime,
      )!;
    } else if (
      this.exhaustedPaginationInDirection("desc") &&
      this.#requestParameters.timestampGt != null
    ) {
      // No previous sibling finished prior to this point; however, there are
      // no more records to fetch in the descending direction which means these
      // records can be merged into the previous sequence if one exists. If
      // a previous sequence existed, its end time would've been set as the
      // request's `timestampGt` filter.
      previousSequence = sequences.find(
        (sequence) => sequence.endTime === this.#requestParameters.timestampGt,
      )!;
    }

    return previousSequence;
  }

  getNextSequence(
    sequences: ReadonlyArray<RecordSequence<TRecordType>>,
  ): RecordSequence<TRecordType> | null {
    this.#requireResolvedResponse();

    let nextSequence: RecordSequence<TRecordType> | null = null;
    if (this.nextSequenceStartTime !== null) {
      // At some earlier point, this tracker's next sibling's request finished
      // with at least one record. Since this tracker's records are guaranteed
      // to be adjacent to that sibling's records, this tracker can be merged
      // directly with its sibling's sequence.
      nextSequence = sequences.find(
        (sequence) => sequence.startTime === this.nextSequenceStartTime,
      )!;
    } else if (
      this.exhaustedPaginationInDirection("asc") &&
      this.#requestParameters.timestampLt != null
    ) {
      // No subsequent sibling finished prior to this point; however, there are
      // no more records to fetch in the ascending direction which means these
      // records can be merged into the next sequence if one exists. If a future
      // sequence existed, its start time would've been set as the request's
      // `timestampLt` filter
      nextSequence = sequences.find(
        (sequence) =>
          this.#requestParameters.timestampLt === sequence.startTime,
      )!;
    }

    return nextSequence;
  }

  /**
   * Returns `true` if this tracker's response could possibly contain the
   * topic's first record according to `topicStartTime`. Always `false` if
   * this tracker's request is sorted by ascending timestamp
   */
  couldHaveFirstRecord(topicStartTime: bigint): boolean {
    return (
      this.#requestParameters.timestampGte === topicStartTime &&
      this.#requestParameters.sort === "desc"
    );
  }

  /**
   * Returns `true` if this tracker's response could possibly contain the
   * topic's last record according to `topicEndTime`. Always `false` if this
   * tracker's request is sorted by descending timestamp
   */
  couldHaveLastRecord(topicEndTime: bigint): boolean {
    return (
      this.#requestParameters.timestampLte === topicEndTime &&
      this.#requestParameters.sort === "asc"
    );
  }

  /**
   * Returns `true` if this tracker's response indicates no more records would
   * be returned if the same filters were used with a larger offset.
   */
  exhaustedPaginationInDirection(direction: "asc" | "desc"): boolean {
    const response = this.#requireResolvedResponse();

    return response.sort === direction && response.data.length < response.limit;
  }

  #requireResolvedResponse(): PlayerRecordListResponse<TRecordType> {
    invariant(this.#response !== null, "Request isn't resolved");

    return this.#response;
  }

  abort(): void {
    this.#controller.abort();
  }

  get aborted(): boolean {
    return this.#controller.signal.aborted;
  }

  #executeRequest({
    onSuccess,
    onError,
  }: Pick<
    RequestTrackerParameters<TRecordType>,
    "onSuccess" | "onError"
  >): void {
    this.#listRecords(
      {
        topicId: this.#topicId,
        frequency: this.#frequency,
        includeCount: false,
        order: "timestamp",
        limit: this.#limit,
        ...this.#requestParameters,
        ...this.#handler.getRequestParams?.(),
      },
      { signal: this.#controller.signal },
    )
      .then((response) => {
        enforceResponseSortCorrectness(response);

        return response;
      })
      .then((processFetchedRecords<TRecordType>).bind(null, this.#handler))
      .then(
        (response) => {
          this.#response = response;

          onSuccess(this, response);
        },
        (error) => {
          onError(this, error);
        },
      );
  }
}

interface CacheEntryParameters<TRecordType extends RecordType> {
  recordType: TRecordType;
  topicId: Topic["id"];
  topicStartTime: bigint;
  topicEndTime: bigint;
  frequency: number | null;
  all?: boolean;
  queryDataFilter?: DataFilter | ReadonlyArray<DataFilter>;
  records?: Array<Array<PlayerRecord<TRecordType>>>;
  evictCacheEntry: (cacheKey: string) => void;
  listRecords: ListRecordsFn;
}

class CacheEntry<TRecordType extends RecordType> {
  readonly recordType: RecordType;
  readonly topicId: Topic["id"];
  topicStartTime: bigint;
  topicEndTime: bigint;
  readonly frequency: number | null;
  readonly all: boolean;
  // Used by all-records-type cache entries
  readonly queryDataFilter: ReadonlyArray<DataFilter>;

  readonly #loopDetector = new LoopDetector();

  readonly #handler: RecordTypeHandler<TRecordType>;

  readonly #sequences: Array<RecordSequence<TRecordType>>;

  readonly #listRecords: ListRecordsFn;
  readonly #trackers = new Set<RequestTracker<TRecordType>>();
  #scheduledFetchesTimeoutId: ReturnType<typeof setTimeout> | null = null;
  #error: unknown = null;

  // Used by all-records-type cache entries
  #controller: AbortController | null = null;
  #canFetch = true;

  readonly #subscribers = new Set<TopicSubscription<TRecordType>>();

  readonly #evictCacheEntry: (cacheKey: string) => void;
  #evictionTimeoutId: ReturnType<typeof setTimeout> | null = null;

  constructor({
    recordType,
    topicId,
    topicStartTime,
    topicEndTime,
    frequency,
    all = false,
    queryDataFilter,
    records = [],
    evictCacheEntry,
    listRecords,
  }: CacheEntryParameters<TRecordType>) {
    this.recordType = recordType;
    this.topicId = topicId;
    this.topicStartTime = topicStartTime;
    this.topicEndTime = topicEndTime;
    this.frequency = frequency;
    this.all = all;
    this.queryDataFilter = CacheEntry.#normalizeDataFilers(queryDataFilter);

    this.#handler = createRecordHandler(recordType);

    this.#sequences = records.map(
      (subRecords) =>
        new RecordSequence({
          records: subRecords,
          hasFirstTopicRecord: subRecords.at(0)!.timestamp === topicStartTime,
          hasLastTopicRecord: subRecords.at(-1)!.timestamp === topicEndTime,
        }),
    );

    this.#listRecords = listRecords;

    this.#evictCacheEntry = evictCacheEntry;

    if (this.all && this.#sequences.length !== 0) {
      invariant(
        this.#sequences.length === 1 &&
          this.#sequences[0].hasFirstTopicRecord &&
          this.#sequences[0].hasLastTopicRecord,
        "An all-records cache entry, if created with records, must be given all records for a topic",
      );

      this.#canFetch = false;
    }
  }

  static #normalizeDataFilers(
    filters: DataFilter | ReadonlyArray<DataFilter> | undefined,
  ): ReadonlyArray<DataFilter> {
    if (filters == null) {
      return [];
    } else if (Array.isArray(filters)) {
      return filters;
    } else {
      return [filters as DataFilter];
    }
  }

  static createCacheKey({
    topicId,
    frequency,
    recordType,
    all = false,
    queryDataFilter,
  }: CacheEntryIdentifier): string {
    // `hashQueryKey` is used here because it produces a stable stringified
    // value regardless of object key ordering.
    // Also, it's important we're only creating the cache key using fields
    // explicitly defined in `CacheEntryIdentifier`, so we need to destructure
    // the parameter and reconstruct it to hash.
    return hashQueryKey([
      {
        topicId,
        frequency,
        recordType,
        all,
        queryDataFilter: CacheEntry.#normalizeDataFilers(queryDataFilter),
      },
    ]);
  }

  getRecords(
    request: RecordsRequest<TRecordType>,
  ): RecordsResponse<TRecordType> {
    if (this.#error) {
      return {
        status: "rejected",
        reason: this.#error,
      };
    }

    let records: Array<PlayerRecord<TRecordType>> | null = null;
    for (const sequence of this.#sequences) {
      records = sequence.getRecords(request);

      if (records !== null) {
        break;
      }
    }

    if (records === null) {
      return {
        status: "pending",
      };
    } else {
      return {
        status: "fulfilled",
        value: records,
      };
    }
  }

  subscribe(subscription: TopicSubscription<TRecordType>): () => void {
    this.#cancelEvictionTimeout();

    this.#subscribers.add(subscription);

    if (checkIsLimitedSubscription(subscription)) {
      this.#loopDetector.addSubscription(subscription);
    }

    this.#fetchRecords(subscription);

    return () => {
      this.#subscribers.delete(subscription);

      if (checkIsLimitedSubscription(subscription)) {
        this.#loopDetector.removeSubscription(subscription);
      }

      if (this.#subscribers.size === 0) {
        this.#scheduleEviction();
      }
    };
  }

  clearError(): void {
    // Reset the loop detector if it's the reason the store is in an error
    // state. Otherwise, it'd likely immediately put the store back into an
    // error state.
    if (this.#error instanceof FetchLoopError) {
      this.#loopDetector.reset();
    }

    this.#error = null;
    this.#canFetch = true;

    this.#scheduleFetches();
    this.#notifySubscribers();
  }

  #fetchRecords(subscription: TopicSubscription<TRecordType>): void {
    if (this.#error) {
      return;
    }

    if (
      isInitializableHandler(this.#handler) &&
      // Once initialization is done (i.e. "fulfilled"), trackers can be created
      this.#handler.getInitializationStatus() !== "fulfilled"
    ) {
      // Don't initialize a second time but also return early so trackers
      // aren't created yet
      if (this.#handler.getInitializationStatus() === "pending") {
        return;
      }

      this.#handler
        .initialize({
          topicId: subscription.topicId,
          listRecords: this.#listRecords,
        })
        .then(() => {
          this.#scheduleFetches();
        })
        .catch((reason) => {
          // Reset handler now so initialization can be retried when user
          // clears error.
          (
            this.#handler as InitializableRecordTypeHandler<TRecordType>
          ).resetInitialization();

          this.#enterErrorState(reason);
        });

      return;
    }

    try {
      if (checkIsLimitedSubscription(subscription)) {
        this.#createAndStoreTrackers(subscription);
      } else {
        this.#fetchAllRecords(subscription);
      }
    } catch (e) {
      this.#enterErrorState(e);
    }
  }

  #fetchAllRecords(
    subscription: AllRecordsTopicSubscription<TRecordType>,
  ): void {
    if (!this.#canFetch) {
      return;
    }

    this.#canFetch = false;

    const {
      topicId,
      frequency,
      queryDataFilter: initialQueryDataFilter,
      limit,
    } = subscription;

    const queryDataFilter = CacheEntry.#normalizeDataFilers(
      initialQueryDataFilter,
    );

    const baseRequest: ListRecordsRequest = {
      topicId,
      sort: "asc",
      order: "timestamp",
      frequency,
      queryDataFilter:
        queryDataFilter.length === 0 ? null : JSON.stringify(queryDataFilter),
      ...this.#handler.getRequestParams?.(),
    };

    this.#controller = new AbortController();

    circumventPagination(
      async (request, init): Promise<PlayerRecordListResponse<TRecordType>> => {
        const response = await this.#listRecords(request, init);

        return processFetchedRecords(this.#handler, response);
      },
      limit,
      baseRequest,
      { signal: this.#controller.signal },
    )
      .then((response) => {
        const seenTimestamps = new Set<bigint>();

        const records = new Array<PlayerRecord<TRecordType>>();
        for (const record of response.data) {
          if (seenTimestamps.has(record.timestamp)) {
            continue;
          }

          seenTimestamps.add(record.timestamp);
          records.push(record);
        }

        if (records.length === 0) {
          throw new NoRecordsError();
        }

        this.#sequences.push(
          new RecordSequence({
            records,
            hasFirstTopicRecord: true,
            hasLastTopicRecord: true,
          }),
        );

        this.topicStartTime = this.#sequences[0].startTime;
        this.topicEndTime = this.#sequences[0].endTime;

        this.#notifySubscribers();
      })
      .catch((reason) => {
        // This rejection could have come from fetching the records or because
        // there weren't any records once fetching completed, particularly if
        // query data filters are used.
        this.#enterErrorState(reason);
      });
  }

  #createAndStoreTrackers(
    subscription: LimitedRecordsTopicSubscription<TRecordType>,
  ): void {
    const { timestamp } = subscription;

    const containingSequence = this.#sequences.find(
      (sequence) =>
        sequence.canHandleTimestamp(timestamp) ||
        // While a sequence may not strictly contain the timestamp, it's
        // possible a tracker exists whose records will be merged with the
        // sequence, effectively "extending" the sequence's start time up to or
        // end time down to the bounds covered by the tracker.
        this.#getTrackersAsArray().some((tracker) =>
          tracker.effectivelyContains(sequence, timestamp),
        ),
    );
    const pastSequence = this.#sequences.findLast(
      (sequence) =>
        sequence.endTime < (containingSequence?.startTime ?? timestamp),
    );
    const futureSequence = this.#sequences.find(
      (sequence) =>
        (containingSequence?.endTime ?? timestamp) < sequence.startTime,
    );

    const requestedDescendingRecordsCount = Math.ceil(subscription.count / 2);

    let descendingRecordsCount =
      requestedDescendingRecordsCount + subscription.prefetchBehind;
    let ascendingRecordsCount =
      subscription.count -
      requestedDescendingRecordsCount +
      subscription.prefetchAhead;
    if (containingSequence !== undefined) {
      const remainingRecords = containingSequence.calculateRemainingRecords({
        timestamp,
        countBehind: descendingRecordsCount,
        countAhead: ascendingRecordsCount,
      });

      descendingRecordsCount = remainingRecords.behind;
      ascendingRecordsCount = remainingRecords.ahead;
    } else if (timestamp < this.topicStartTime) {
      ascendingRecordsCount += descendingRecordsCount;
      descendingRecordsCount = 0;
    } else if (timestamp >= this.topicEndTime) {
      descendingRecordsCount += ascendingRecordsCount;
      ascendingRecordsCount = 0;
    }

    const timestampFilters = {
      descending: {
        ...(pastSequence === undefined
          ? { timestampGte: this.topicStartTime }
          : { timestampGt: pastSequence.endTime }),
        ...(containingSequence === undefined
          ? { timestampLte: timestamp }
          : { timestampLt: containingSequence.startTime }),
      },
      ascending: {
        timestampGt: containingSequence?.endTime ?? timestamp,
        ...(futureSequence === undefined
          ? { timestampLte: this.topicEndTime }
          : { timestampLt: futureSequence.startTime }),
      },
    };

    let head: RequestTracker<TRecordType> | null = null;
    let tail: RequestTracker<TRecordType> | null = null;

    const createTracker = (
      requestParameters: RequestParameters,
    ): RequestTracker<TRecordType> => {
      const tracker = new RequestTracker({
        topicId: subscription.topicId,
        frequency: subscription.frequency,
        limit: subscription.limit,
        handler: this.#handler,
        requestParameters,
        listRecords: this.#listRecords,
        onSuccess: this.#handleResponseData,
        onError: this.#handleResponseError,
      });

      if (head === null || tail === null) {
        head = tracker;
        tail = tracker;
      } else {
        tracker.previousSibling = tail;
        tail.nextSibling = tracker;
        tail = tracker;
      }

      return tracker;
    };

    if (
      descendingRecordsCount > 0 &&
      !this.#getTrackersAsArray().some((tracker) =>
        tracker.blocks(timestampFilters.descending),
      )
    ) {
      // Will throw if detector thinks store is in a fetching loop
      this.#loopDetector.recordIteration(subscription, "desc");

      const descendingRequestsCount = Math.ceil(
        descendingRecordsCount / subscription.limit,
      );

      // The final array of requests needs to be sorted by ascending timestamp
      // of the records each is expected to return. Since requests for past
      // records are sorted by descending timestamp the requests need to be
      // pushed in reverse order. The fetched records will also need to be
      // reversed too.
      for (
        let requestIndex = descendingRequestsCount - 1;
        requestIndex >= 0;
        requestIndex--
      ) {
        const tracker = createTracker({
          ...timestampFilters.descending,
          sort: "desc",
          offset: requestIndex * subscription.limit,
        });

        if (requestIndex === 0 && containingSequence !== undefined) {
          tracker.nextSequenceStartTime = containingSequence.startTime;
        }
      }
    }

    // If the descending and ascending requests are separated by an existing
    // sequence which contains the timestamp, their records will not be
    // contiguous, meaning they shouldn't be treated as siblings. Instead,
    // all descending requests are treated as their own set of siblings and
    // the ascending requests will be treated as their own too.
    if (head !== null && containingSequence !== undefined) {
      this.#trackers.add(head);

      head = null;
      tail = null;
    }

    if (
      ascendingRecordsCount > 0 &&
      !this.#getTrackersAsArray().some((tracker) =>
        tracker.blocks(timestampFilters.ascending),
      )
    ) {
      // Will throw if detector thinks store is in a fetching loop
      this.#loopDetector.recordIteration(subscription, "asc");

      const ascendingRequestsCount = Math.ceil(
        ascendingRecordsCount / subscription.limit,
      );

      for (
        let requestIndex = 0;
        requestIndex < ascendingRequestsCount;
        requestIndex++
      ) {
        const tracker = createTracker({
          ...timestampFilters.ascending,
          sort: "asc",
          offset: requestIndex * subscription.limit,
        });

        if (requestIndex === 0 && containingSequence !== undefined) {
          tracker.previousSequenceEndTime = containingSequence.endTime;
        }
      }
    }

    if (head !== null) {
      this.#trackers.add(head);
    }
  }

  #handleResponseData = (
    tracker: RequestTracker<TRecordType>,
    response: PlayerRecordListResponse<TRecordType>,
  ): void => {
    // Request was aborted earlier for some reason. Tracker should already
    // have been removed and there's nothing new to notify subscribers of.
    if (tracker.aborted) {
      return;
    }

    const previousSequence = tracker.getPreviousSequence(this.#sequences);
    if (previousSequence !== null) {
      removeArrayElement(this.#sequences, previousSequence);
    }

    const nextSequence = tracker.getNextSequence(this.#sequences);
    if (nextSequence !== null) {
      removeArrayElement(this.#sequences, nextSequence);
    }

    const { previousSibling, nextSibling } = tracker;

    let newSequence: RecordSequence<TRecordType> | null = null;
    if (response.data.length > 0) {
      // Records <= the playback time get fetched in descending order but need
      // to be stored in ascending order.
      const records =
        response.sort === "desc" ? [...response.data].reverse() : response.data;

      newSequence = new RecordSequence({
        records,
        hasFirstTopicRecord: records.at(0)!.timestamp === this.topicStartTime,
        hasLastTopicRecord: records.at(-1)!.timestamp === this.topicEndTime,
      });

      // It's possible the first or last record in a topic (whose timestamps
      // are used for a topic's `startTime` and `endTime`) could have been
      // deleted between the topic being fetched and the store attempting
      // to fetch those records. This would make the topic's `startTime` and/or
      // `endTime` different from what was originally stored on this entry.
      // This entry must be able to recognize this and update the stored topic
      // endpoints else it might get caught in a loop trying to fetch records
      // that no longer exist.
      if (
        (tracker.couldHaveFirstRecord(this.topicStartTime) &&
          tracker.exhaustedPaginationInDirection("desc")) ||
        tracker.noPriorRecords
      ) {
        newSequence.hasFirstTopicRecord = true;
        this.topicStartTime = newSequence.startTime;
      }

      if (
        (tracker.couldHaveLastRecord(this.topicEndTime) &&
          tracker.exhaustedPaginationInDirection("asc")) ||
        tracker.noSubsequentRecords
      ) {
        newSequence.hasLastTopicRecord = true;
        this.topicEndTime = newSequence.endTime;
      }

      // If this tracker had siblings, its new sequence's start or end time
      // should be stored directly on them so once the siblings' records are
      // fetched they can be merged in directly. This is possible since:
      //   1. All trackers were initially sorted such that the resulting
      //      sequences could conceptually all be concatenated and still be
      //      sorted correctly.
      //   2. Offsets for all trackers fetching in the same sort direction
      //      will produce contiguous records.
      //   3. Timestamp filters between trackers fetching in opposite directions
      //      ensure the first record <= the playback time and the first record
      //      > the playback time are sequential.
      if (previousSibling !== null) {
        previousSibling.nextSequenceStartTime = newSequence.startTime;
      }

      if (nextSibling !== null) {
        nextSibling.previousSequenceEndTime = newSequence.endTime;
      }
    } else {
      // Since the response had no records there isn't a new sequence whose
      // bounds should be set on its siblings; however, if some sequences exist
      // that would've been merged with the new sequence, those can be set on
      // the siblings so they can eventually merge with those. Without these
      // checks then certain gaps between sequences may never be merged.
      if (nextSequence !== null && previousSibling !== null) {
        previousSibling.nextSequenceStartTime = nextSequence.startTime;
      }

      if (previousSequence !== null && nextSibling !== null) {
        nextSibling.previousSequenceEndTime = previousSequence.endTime;
      }

      // See comment in if-block above explaining why topic start and end times
      // might need updated. In the below cases, this tracker's response doesn't
      // have the first or last record since it had 0 records but its sort
      // direction and timestamp filters indicate it could've.
      if (tracker.couldHaveFirstRecord(this.topicStartTime)) {
        // Does `nextSequence !== null` imply `nextSibling === null`?
        if (nextSequence !== null && nextSibling === null) {
          // This tracker would've merged with the next sequence if it had
          // records. Since it was expected to possibly contain the topic's
          // first record but 0 were found, the next sequence should be treated
          // as containing the first record.
          nextSequence.hasFirstTopicRecord = true;
          this.topicStartTime = nextSequence.startTime;
        } else if (nextSibling !== null) {
          // While it's not guaranteed the topic's original first record has
          // been deleted - this branch could be reached if this tracker had
          // too large of an offset and its next sibling contains the first
          // record - it's certain there won't be any records prior to its next
          // siblings'. However, this check is important when deleted records
          // make it such that the topic's new first record will be returned
          // in an ascending response which, typically, would not be expected
          // to contain the first record. By marking the sibling like this, any
          // adjustments to the topic's start or end times can be determined
          // when the sibling resolves.
          nextSibling.noPriorRecords = true;
        }
      }

      if (tracker.couldHaveLastRecord(this.topicEndTime)) {
        if (previousSequence !== null && previousSibling === null) {
          previousSequence.hasLastTopicRecord = true;
          this.topicEndTime = previousSequence.endTime;
        } else if (previousSibling !== null) {
          previousSibling.noSubsequentRecords = true;
        }
      }
    }

    if (tracker.exhaustedPaginationInDirection("desc")) {
      // Cancel any ongoing requests in the descending direction as it's now
      // known they won't return any records.
      let obsoleteTracker = previousSibling;
      while (obsoleteTracker !== null) {
        obsoleteTracker.abort();

        if (obsoleteTracker.previousSibling === null) {
          // This tracker is the head of its chain of siblings which means
          // it's a member of the set of trackers. It needs to be removed so
          // it doesn't end up blocking future requests.
          this.#trackers.delete(obsoleteTracker);
        }

        obsoleteTracker = obsoleteTracker.previousSibling;
      }
    }

    if (tracker.exhaustedPaginationInDirection("asc")) {
      // Cancel any ongoing requests in the ascending direction as it's now
      // known they won't return any records.
      let obsoleteTracker = nextSibling;
      while (obsoleteTracker !== null) {
        obsoleteTracker.abort();

        obsoleteTracker = obsoleteTracker.nextSibling;
      }
    }

    if (
      previousSequence !== null ||
      newSequence !== null ||
      nextSequence !== null
    ) {
      this.#sequences.push(
        RecordSequence.from(previousSequence, newSequence, nextSequence),
      );
      // Sort the sequences in place since the merged sequence above was just
      // pushed onto the end
      this.#sequences.sort((a, b) => a.compareTo(b));
    }

    this.#removeTracker(tracker);
    this.#scheduleFetches();
    this.#notifySubscribers();
  };

  #handleResponseError = (
    tracker: RequestTracker<TRecordType>,
    error: unknown,
  ): void => {
    // Request was aborted earlier for some reason. Tracker should already
    // have been removed and there's nothing new to notify subscribers of.
    if (tracker.aborted) {
      return;
    }

    this.#removeTracker(tracker);
    this.#enterErrorState(error);
  };

  #enterErrorState(error: unknown): void {
    this.#error = error;

    this.#cancelScheduledFetches();
    this.#notifySubscribers();
  }

  #scheduleFetches(): void {
    if (this.#error) {
      return;
    }

    if (this.#scheduledFetchesTimeoutId !== null) {
      return;
    }

    // Once some meaningful change has occurred, each subscriber's request
    // parameters must be checked again to see if they're all fulfilled or
    // if more fetch requests are needed. This is necessary since ongoing
    // requests can block other requests and the former, once finished, might
    // not contain the records needed by the latter. By scheduling a timeout
    // rather than doing this synchronously, any pending responses will get a
    // chance to flush and get their records merged in first and the scheduled
    // fetches can be cancelled if needed.
    this.#scheduledFetchesTimeoutId = setTimeout(() => {
      this.#subscribers.forEach((subscriber) => {
        this.#fetchRecords(subscriber);
      });

      this.#scheduledFetchesTimeoutId = null;
    }, 0);
  }

  #cancelScheduledFetches(): void {
    if (this.#scheduledFetchesTimeoutId === null) {
      return;
    }

    clearTimeout(this.#scheduledFetchesTimeoutId);
    this.#scheduledFetchesTimeoutId = null;
  }

  #getTrackersAsArray(): ReadonlyArray<RequestTracker<TRecordType>> {
    const trackersArray = new Array<RequestTracker<TRecordType>>();

    this.#trackers.forEach((tracker) => {
      let currentTracker: RequestTracker<TRecordType> | null = tracker;
      while (currentTracker !== null) {
        trackersArray.push(currentTracker);

        currentTracker = currentTracker.nextSibling;
      }
    });

    return trackersArray;
  }

  #removeTracker(tracker: RequestTracker<TRecordType>): void {
    const { previousSibling, nextSibling } = tracker;

    if (previousSibling !== null) {
      previousSibling.nextSibling = null;
    } else {
      this.#trackers.delete(tracker);
    }

    if (nextSibling !== null) {
      nextSibling.previousSibling = null;

      this.#trackers.add(nextSibling);
    }
  }

  #notifySubscribers(): void {
    this.#subscribers.forEach((subscriber) => subscriber.notify());
  }

  #cancelEvictionTimeout(): void {
    if (this.#evictionTimeoutId === null) {
      return;
    }

    clearTimeout(this.#evictionTimeoutId);

    this.#evictionTimeoutId = null;
  }

  #scheduleEviction(): void {
    if (this.#subscribers.size > 0) {
      return;
    }

    if (this.#evictionTimeoutId !== null) {
      return;
    }

    // To avoid a memory leak, this entry should be removed from the store if
    // it no longer has subscribers. However, because React's
    // `useSyncExternalStore`, as it's currently being used, will cause panels
    // to unsubscribe and resubscribe after each render, eviction needs to
    // happen some time later, otherwise data that's still needed might be
    // throw out. React's strict effects in development would also cause it to
    // subscribe and unsubscribe more than once.
    this.#evictionTimeoutId = setTimeout(() => {
      this.#evictionTimeoutId = null;

      this.#trackers.forEach((tracker) => tracker.abort());

      this.#controller?.abort();

      // For record types like "threeD", each record needs to perform some
      // cleanup prior to being evicted from the store. Don't waste time
      // iterating records if this handler doesn't perform disposal, though.
      if (this.#handler.dispose !== undefined) {
        for (const sequence of this.#sequences) {
          for (const record of sequence.records) {
            this.#handler.dispose(record);
          }
        }
      }

      this.#evictCacheEntry(CacheEntry.createCacheKey(this));
    }, 15_000);
  }
}

export class RecordStore {
  readonly #listRecords: ListRecordsFn;

  readonly #entries = new Map<string, CacheEntry<any>>();

  constructor(listRecords: ListRecordsFn) {
    this.#listRecords = listRecords;
  }

  getRecords<TRecordType extends RecordType>(
    request: RecordsRequest<TRecordType>,
  ): RecordsResponse<TRecordType> {
    const entry = this.#entries.get(CacheEntry.createCacheKey(request));

    if (entry === undefined) {
      return { status: "pending" };
    } else {
      return entry.getRecords(request);
    }
  }

  subscribe(subscription: TopicSubscription<RecordType>): () => void {
    if (!isBoundedTopicSubscription(subscription)) {
      // This is a no-op since no actual subscription is created.
      return () => {};
    }

    const entry = emplace(
      this.#entries,
      CacheEntry.createCacheKey(subscription),
      {
        insert: () =>
          new CacheEntry({
            ...subscription,
            evictCacheEntry: this.#evictEntry,
            listRecords: this.#listRecords,
          }),
      },
    );

    return entry.subscribe(subscription);
  }

  clearError(identifier: CacheEntryIdentifier): void {
    const cacheKey = CacheEntry.createCacheKey(identifier);

    const entry = this.#entries.get(cacheKey);

    invariant(
      entry !== undefined,
      `No entry exists with cache key "${cacheKey}"`,
    );

    entry.clearError();
  }

  seedCacheEntry<TRecordType extends RecordType>(
    parameters: StrictOmit<
      MarkRequired<CacheEntryParameters<TRecordType>, "records">,
      "evictCacheEntry" | "listRecords"
    >,
  ): void {
    const cacheKey = CacheEntry.createCacheKey(parameters);

    invariant(!this.#entries.has(cacheKey), "Cache entry already exists");

    this.#entries.set(
      cacheKey,
      new CacheEntry({
        ...parameters,
        evictCacheEntry: this.#evictEntry,
        listRecords: this.#listRecords,
      }),
    );
  }

  #evictEntry = (cacheKey: string): void => {
    const entryExisted = this.#entries.delete(cacheKey);

    invariant(entryExisted, `No entry existed with cache key "${cacheKey}"`);
  };
}

function isBoundedTopicSubscription<TRecordType extends RecordType>(
  subscription: TopicSubscription<TRecordType>,
): subscription is BoundedTopicSubscription<TRecordType> {
  return (
    subscription.topicStartTime != null && subscription.topicEndTime != null
  );
}

function checkIsAllRecordsRequest<TRecordType extends RecordType>(
  request: RecordsRequest<TRecordType>,
): request is AllRecordsRequest<TRecordType> {
  return request.all === true;
}

function checkIsLimitedSubscription<TRecordType extends RecordType>(
  subscription: TopicSubscription<TRecordType>,
): subscription is LimitedRecordsTopicSubscription<TRecordType> {
  return subscription.all !== true;
}

function removeArrayElement(array: Array<unknown>, element: unknown): void {
  const index = array.indexOf(element);

  if (index === -1) {
    return;
  }

  array.splice(index, 1);
}

/**
 * Given timestamp filters, convert them to the corresponding closed interval
 * as a time range.
 */
function toClosedInterval({
  timestampGt,
  timestampGte,
  timestampLt,
  timestampLte,
}: RequestParameters): TimeRange {
  // Comparing time ranges is simpler if they're closed intervals. The exclusive
  // timestamp filters < and > can be represented using <= or >=, respectively,
  // by adding or subtracting 1 since timestamps belong to the natural numbers.
  // For example, `timestampGt: 3` is equivalent to `timestampGte: 4` since 4 is
  // 3's successor.

  // Prettier removes the parentheses since they're technically unnecessary but
  // that makes it less apparent what's happening.
  return {
    // prettier-ignore
    startTime: timestampGte ?? (timestampGt + 1n),
    // prettier-ignore
    endTime: timestampLte ?? (timestampLt - 1n),
  };
}

function doIntervalsOverlap(first: TimeRange, second: TimeRange): boolean {
  return second.startTime <= first.endTime && first.startTime <= second.endTime;
}

function enforceResponseSortCorrectness(response: RecordListResponse) {
  // Can only check sort order if there's at least 2 records
  if (response.data.length <= 1) {
    return;
  }

  // Making the assumption that the backend returns all records in the same
  // sort order but that order may be the opposite of what was requested. Under
  // that assumption, it's sufficient to check the sort order of just the first
  // two records rather than spending time checking every record.
  let isSortedCorrectly: boolean;
  if (response.sort === "desc") {
    isSortedCorrectly = response.data[0].timestamp > response.data[1].timestamp;
  } else {
    isSortedCorrectly = response.data[0].timestamp < response.data[1].timestamp;
  }

  invariant(
    isSortedCorrectly,
    "Records do not appear to be sorted in correct order",
  );
}
