import { useMemo } from "react";
import type { Topic } from "~/lqs";
import type { AnyTopicDescriptor } from "../panels";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../playback";
import type { SampleFrequencyValue, TimestepValue } from "../types";
import { SampleFrequency, Timestep } from "../types";
import type { RecordStore } from "./store";
import type {
  PlayerRecord,
  RecordsResponse,
  RecordType,
  StoreSnapshot,
} from "./types";
import type { CanReuseSnapshotFn } from "./use-store-snapshot";
import { useStoreSnapshot } from "./use-store-snapshot";

interface StoreRequest<
  TRecordType extends RecordType,
  TTopicDescriptor extends AnyTopicDescriptor,
> {
  recordType: TRecordType;
  topic: Topic;
  descriptor: TTopicDescriptor;
  timestamp: bigint;
  frequency: SampleFrequencyValue;
  count: number;
}

export type BasicRecordsSnapshot<
  TRecordType extends RecordType,
  TTopicDescriptor extends AnyTopicDescriptor,
> = StoreSnapshot<
  StoreRequest<TRecordType, TTopicDescriptor>,
  Array<PlayerRecord<TRecordType>>
>;

function subscribeToStore<TRecordType extends RecordType>(
  recordType: TRecordType,
  topic: Topic,
  frequency: SampleFrequencyValue,
  count: number,
  timestamp: bigint,
  limit: number,
  prefetchBehind: number,
  prefetchAhead: number,
  store: RecordStore,
  notify: () => void,
): () => void {
  return store.subscribe({
    recordType,
    topicId: topic.id,
    frequency,
    count,
    timestamp,
    limit,
    topicStartTime: topic.startTime,
    topicEndTime: topic.endTime,
    prefetchBehind,
    prefetchAhead,
    notify,
  });
}

function getStoreSnapshot<TRecordType extends RecordType>(
  recordType: TRecordType,
  topic: Topic,
  frequency: SampleFrequencyValue,
  count: number,
  timestamp: bigint,
  store: RecordStore,
): RecordsResponse<TRecordType> {
  return store.getRecords({
    recordType,
    topicId: topic.id,
    timestamp,
    count,
    frequency,
  });
}

export interface UseRecordsParameters<
  TRecordType extends RecordType,
  TTopicDescriptor extends AnyTopicDescriptor,
> {
  recordType: TRecordType;
  topic: Topic;
  descriptor: TTopicDescriptor;
  count: number;
  timestepOverride?: TimestepValue;
  limit: number;
  prefetchBehind?: number;
  prefetchAhead?: number;
  canReuseSnapshot?: CanReuseSnapshotFn<
    StoreRequest<TRecordType, TTopicDescriptor>,
    Array<PlayerRecord<TRecordType>>
  >;
}

export function useRecords<
  TRecordType extends RecordType,
  TTopicDescriptor extends AnyTopicDescriptor,
>({
  recordType,
  topic,
  descriptor,
  timestepOverride,
  count,
  limit,
  prefetchBehind = 0,
  prefetchAhead = 0,
  canReuseSnapshot,
}: UseRecordsParameters<TRecordType, TTopicDescriptor>): {
  snapshot: BasicRecordsSnapshot<TRecordType, TTopicDescriptor>;
  isPlaceholder: boolean;
} {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const frequency = getFrequencyForTimestep(
    timestepOverride ?? playbackSettings.timestep,
  );

  return useStoreSnapshot(
    useMemo(() => {
      const request: StoreRequest<TRecordType, TTopicDescriptor> = {
        recordType,
        topic,
        descriptor,
        timestamp: playbackSource.timestamp,
        frequency,
        count,
      };

      return {
        request,
        subscribe: subscribeToStore.bind(
          null,
          recordType,
          topic,
          frequency,
          count,
          playbackSource.timestamp,
          limit,
          prefetchBehind,
          prefetchAhead,
        ),
        getSnapshot: (getStoreSnapshot<TRecordType>).bind(
          null,
          recordType,
          topic,
          frequency,
          count,
          playbackSource.timestamp,
        ),
        canReuseSnapshot,
      };
    }, [
      recordType,
      topic,
      descriptor,
      playbackSource.timestamp,
      frequency,
      count,
      limit,
      prefetchBehind,
      prefetchAhead,
      canReuseSnapshot,
    ]),
  );
}

function getFrequencyForTimestep(
  timestep: TimestepValue,
): SampleFrequencyValue {
  return {
    [Timestep.Second]: SampleFrequency.Second,
    [Timestep.HalfSecond]: SampleFrequency.HalfSecond,
    [Timestep.Decisecond]: SampleFrequency.Decisecond,
  }[timestep];
}
