import React from "react";
import { Button, ButtonGroup, Slider, Typography } from "@mui/material";
import { OrbitControls } from "@react-three/drei/core/OrbitControls";
import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../../actions";
import { SourceTopicSelect } from "../../components";
import type { ThreeDPanel } from "../../panels";
import { getPrimaryTopicDescriptor } from "../../panels";
import {
  useGetCameraControls,
  useManageCameraControlsRef,
} from "./controls-provider";
import { useThreeDRecords } from "./use-three-d-records";

export function ThreeDVisualizationControls({
  panel,
  topic,
}: {
  panel: ThreeDPanel;
  topic: Topic;
}) {
  const playerActions = usePlayerActions();

  const threeDRecords = useThreeDRecords({ panel, topic });
  const disabled = threeDRecords.snapshot.status !== "fulfilled";

  const getCameraControls = useGetCameraControls(panel.id);

  function handlePointSizeChange(
    _: unknown,
    newPointSize: number | Array<number>,
  ) {
    invariant(
      typeof newPointSize === "number",
      "Expected value to be a single number",
    );

    playerActions.setPointCloudPointSize(panel, newPointSize);
  }

  function renderRotationButton(axis: "x" | "y" | "z") {
    function handleRotate() {
      getCameraControls()?.rotate(axis, Math.PI);
    }

    return <Button onClick={handleRotate}>{axis}</Button>;
  }

  function handleResetScene() {
    getCameraControls()?.reset();
  }

  return (
    <>
      <SourceTopicSelect
        panel={panel}
        topic={topic}
        sx={{ marginBlockEnd: 2 }}
      />
      <Typography>Point Size</Typography>
      <Slider
        sx={{
          alignSelf: "center",
          width: (theme) => `calc(100% - ${theme.spacing(2.5)})`,
        }}
        disabled={disabled}
        min={0}
        max={0.1}
        step={0.005}
        value={getPrimaryTopicDescriptor(panel).size}
        onChange={handlePointSizeChange}
      />
      <Typography sx={{ mt: 2 }}>Rotate Scene on Axis</Typography>
      <ButtonGroup
        color="primary"
        variant="contained"
        fullWidth
        disableElevation
        disabled={disabled}
      >
        {renderRotationButton("x")}
        {renderRotationButton("y")}
        {renderRotationButton("z")}
      </ButtonGroup>
      <Button
        sx={{ mt: 4 }}
        color="primary"
        variant="contained"
        fullWidth
        disableElevation
        disabled={disabled}
        onClick={handleResetScene}
      >
        Reset Scene
      </Button>
    </>
  );
}

export interface CameraActions {
  rotate: (axis: "x" | "y" | "z", angleRads: number) => void;
  reset: () => void;
}

export function CameraControls({ panel }: { panel: ThreeDPanel }) {
  useManageCameraControlsRef(panel.id);

  return <OrbitControls />;
}
