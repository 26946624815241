import { useMemo } from "react";
import type { Topic } from "~/lqs";
import type { AsyncOperationSnapshot } from "~/types";
import type { AnyTopicDescriptor } from "../panels";
import type { DataFilter } from "../types";
import { SampleFrequency } from "../types";
import type { RecordStore } from "./store";
import type { PlayerRecord, StoreSnapshot } from "./types";
import { useStoreSnapshot } from "./use-store-snapshot";

interface StoreRequest<TTopicDescriptor extends AnyTopicDescriptor> {
  topic: Topic | null;
  descriptor: TTopicDescriptor | null;
  queryDataFilter?: DataFilter | ReadonlyArray<DataFilter>;
}

function subscribeToStore(
  topic: Topic | null,
  queryDataFilter: DataFilter | ReadonlyArray<DataFilter> | undefined,
  store: RecordStore,
  notify: () => void,
): () => void {
  if (topic == null) {
    return () => {};
  }

  return store.subscribe({
    recordType: "default",
    topicId: topic.id,
    frequency: SampleFrequency.Second,
    all: true,
    queryDataFilter,
    limit: 100,
    topicStartTime: topic.startTime,
    topicEndTime: topic.endTime,
    notify,
  });
}

function getSnapshot<TData = Array<PlayerRecord<"default">>>(
  topic: Topic | null,
  queryDataFilter: DataFilter | ReadonlyArray<DataFilter> | undefined,
  select: ((records: Array<PlayerRecord<"default">>) => TData) | undefined,
  store: RecordStore,
): AsyncOperationSnapshot<TData> {
  if (topic == null) {
    return { status: "pending" };
  }

  const response = store.getRecords({
    recordType: "default",
    topicId: topic.id,
    frequency: SampleFrequency.Second,
    all: true,
    queryDataFilter,
  });

  if (response.status !== "fulfilled") {
    return response;
  }

  return {
    ...response,
    value: (select == null ? response.value : select(response.value)) as TData,
  };
}

export function useAllRecords<
  TTopicDescriptor extends AnyTopicDescriptor,
  TData = Array<PlayerRecord<"default">>,
>({
  topic,
  descriptor,
  queryDataFilter,
  select,
}: {
  topic: Topic | null;
  descriptor: TTopicDescriptor | null;
  queryDataFilter?: DataFilter | ReadonlyArray<DataFilter>;
  select?: (records: Array<PlayerRecord<"default">>) => TData;
}): {
  snapshot: StoreSnapshot<StoreRequest<TTopicDescriptor>, TData>;
  isPlaceholder: boolean;
} {
  return useStoreSnapshot(
    useMemo(() => {
      const request: StoreRequest<TTopicDescriptor> = {
        topic,
        descriptor,
        queryDataFilter,
      };

      return {
        request,
        subscribe: subscribeToStore.bind(null, topic, queryDataFilter),
        getSnapshot: (getSnapshot<TData>).bind(
          null,
          topic,
          queryDataFilter,
          select,
        ),
        canReuseSnapshot() {
          return request.topic != null;
        },
      };
    }, [topic, descriptor, queryDataFilter, select]),
  );
}
