import { invariant } from "~/lib/invariant";
import type { VersionHistory } from "./types";

// See semver docs on RegEx for parsing semantic version (simplified since we
// only plan to use "major.minor.patch"):
// https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
const SEMVER_REGEX =
  /^(?<major>0|[1-9]\d*)\.(?<minor>0|[1-9]\d*)\.(?<patch>0|[1-9]\d*)$/;

export type VersionComparisonStatus = "unavailable" | "current" | "deprecated";

export class ApiVersion {
  readonly major: number;
  readonly minor: number;
  readonly patch: number;

  constructor(major: number, minor: number, patch: number) {
    this.major = major;
    this.minor = minor;
    this.patch = patch;
  }

  static create(major: number, minor: number, patch: number): ApiVersion {
    return new ApiVersion(major, minor, patch);
  }

  static safeParse(
    versionString: string,
  ): { success: true; version: ApiVersion } | { success: false } {
    try {
      return { success: true, version: ApiVersion.parse(versionString) };
    } catch {
      return { success: false };
    }
  }

  static parse(versionString: string): ApiVersion {
    const match = SEMVER_REGEX.exec(versionString);
    invariant(match != null, "Unable to determine API version");

    const groups = match.groups!;

    const major = Number(groups.major);
    const minor = Number(groups.minor);
    const patch = Number(groups.patch);

    return ApiVersion.create(major, minor, patch);
  }

  toString(): string {
    return `${this.major}.${this.minor}.${this.patch}`;
  }

  toJSON(): string {
    return this.toString();
  }

  gt(other: ApiVersion): boolean {
    return this.#compareTo(other) > 0;
  }

  checkStatus(history: VersionHistory): VersionComparisonStatus {
    if (history.added) {
      const comparison = this.#compareTo(history.added);
      if (comparison < 0) {
        // This version is strictly before the version when the subject was added
        return "unavailable";
      }
    }

    if (history.deprecated) {
      const comparison = this.#compareTo(history.deprecated);
      if (comparison >= 0) {
        // This version is at or after the version when the subject was deprecated
        return "deprecated";
      }
    }

    // The subject was added by this version and not yet deprecated, so it's
    // considered current
    return "current";
  }

  // See semver specification, section (11), describing how to compare two versions:
  // https://semver.org/#semantic-versioning-specification-semver
  #compareTo(other: ApiVersion): number {
    const majorComparison = this.major - other.major;
    if (majorComparison !== 0) {
      return majorComparison;
    }

    const minorComparison = this.minor - other.minor;
    if (minorComparison !== 0) {
      return minorComparison;
    }

    const patchComparison = this.patch - other.patch;
    return patchComparison;
  }
}

export const v = ApiVersion.create;
