import React from "react";
import type { Maybe } from "~/types";
import { assertNever } from "~/utils";
import type { ImagePanel, InferenceTopicDescriptor } from "../panels";
import type { ImageFrame } from "../visualizations/image-visualization/types";
import type { DetectionInferenceResults } from "./detections";
import { DetectionResultsVisualization } from "./detections";
import type { ImageInferenceResults } from "./images";
import { ImageResultsVisualization } from "./images";
import { ImageSegmentationsResultsVisualization } from "./segmentations";

export type InferenceResults =
  | DetectionInferenceResults
  | ImageInferenceResults;

export function InferenceResultsVisualization({
  panel,
  inferenceTopicDescriptor,
  imageFrame,
}: {
  panel: ImagePanel;
  inferenceTopicDescriptor: Maybe<InferenceTopicDescriptor>;
  imageFrame: ImageFrame;
}) {
  const { inferenceFrames } = imageFrame;

  if (inferenceFrames?.current == null || inferenceTopicDescriptor == null) {
    return null;
  }

  switch (inferenceFrames.type) {
    case "object-detection":
    case "zero-shot-object-detection": {
      return (
        <DetectionResultsVisualization
          inferenceTopicDescriptor={inferenceTopicDescriptor}
          results={inferenceFrames.current}
        />
      );
    }
    case "image-segmentation": {
      return (
        <ImageSegmentationsResultsVisualization
          inferenceTopicDescriptor={inferenceTopicDescriptor}
          results={inferenceFrames.current}
        />
      );
    }
    case "depth-estimation": {
      return (
        <ImageResultsVisualization
          panelId={panel.id}
          inferenceTopicDescriptor={inferenceTopicDescriptor}
          results={inferenceFrames.current}
        />
      );
    }
    default: {
      assertNever(inferenceFrames);
    }
  }
}
