import type { ElementOf } from "ts-essentials";
import type { Topic } from "~/lqs";
import type { ThreeDPanel } from "../../panels";
import { getPrimaryTopicDescriptor, VisualizationType } from "../../panels";
import type { BasicRecordsSnapshot } from "../../record-store";
import { useRecords } from "../../record-store";
import { useVisualizationStoreParams } from "../context";

export function useThreeDRecords({
  panel,
  topic,
}: {
  panel: ThreeDPanel;
  topic: Topic;
}): {
  snapshot: BasicRecordsSnapshot<"threeD", ElementOf<ThreeDPanel["topics"]>>;
  isPlaceholder: boolean;
} {
  const storeParams = useVisualizationStoreParams(VisualizationType.ThreeD);
  return useRecords({
    recordType: "threeD",
    topic,
    descriptor: getPrimaryTopicDescriptor(panel),
    ...storeParams,
  });
}
