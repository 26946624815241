import React from "react";
import { createSafeContext } from "~/contexts";
import type {
  AsyncDefaultProfile,
  PanelLayoutAction,
  TopicInitializationOverrider,
} from "./reducer";
import { usePanelLayoutReducer } from "./reducer";
import type { LayoutNode, Panel } from "./types";

interface PanelLayoutContextValue {
  layout: LayoutNode;
  panels: ReadonlyArray<Panel>;
  showFutureDefaultProfileWarning: boolean;
  dispatch: React.Dispatch<PanelLayoutAction>;
}

export const [usePanelLayoutContext, PanelLayoutContext] =
  createSafeContext<PanelLayoutContextValue>("PanelLayout");

export function PanelLayoutProvider({
  defaultProfile,
  overrideInitializationState,
  children,
}: {
  /**
   * A query with the default layout to use
   */
  defaultProfile?: AsyncDefaultProfile;
  overrideInitializationState?: TopicInitializationOverrider;
  children: React.ReactNode;
}) {
  const value = usePanelLayoutReducer({
    defaultProfile,
    overrideInitializationState,
  });

  return (
    <PanelLayoutContext.Provider value={value}>
      {children}
    </PanelLayoutContext.Provider>
  );
}
