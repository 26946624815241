import { VisualizationType } from "./constants";
import type * as sdkTypes from "./sdk";
import type { InitializedPanel, Panel } from "./types";

export function serializePanels(
  panels: ReadonlyArray<Panel>,
): ReadonlyArray<sdkTypes.PanelDescriptor> {
  return panels.map((panel): sdkTypes.PanelDescriptor => {
    const { id, title, visualization, topics } = panel;

    switch (visualization) {
      case VisualizationType.Image: {
        // Only grab the primary image topic so if there was an inference topic
        // it won't be serialized
        const [imageTopic] = topics;

        return {
          id,
          title,
          visualization,
          topics: [imageTopic],
        };
      }
      default: {
        return {
          id,
          title,
          visualization,
          topics,
        } as sdkTypes.PanelDescriptor;
      }
    }
  });
}

export function deserializePanels(
  descriptors: ReadonlyArray<sdkTypes.PanelDescriptor>,
): ReadonlyArray<Panel> {
  return descriptors.map((descriptor): Panel => {
    const base = {
      ...descriptor,
      topicSelectionConfig: {
        search: null,
        [VisualizationType.Image]: false,
        [VisualizationType.ThreeD]: false,
        [VisualizationType.Map]: false,
        lastSelected: null,
      },
    };

    if (base.visualization === null) {
      return base;
    } else {
      return {
        ...base,
        hasAutoSkipped: false,
        selectedTag: null,
        ...(base.visualization === VisualizationType.Chart && {
          showOverview: false,
          topics: base.topics.map((chartTopic) => ({
            ...chartTopic,
            showFields: true,
          })),
        }),
      } as InitializedPanel;
    }
  });
}
