import { useMemo } from "react";
import { millisecondsToNanoseconds, secondsToNanoseconds } from "~/lib/dates";
import type { Topic } from "~/lqs";
import type { AsyncOperationSnapshot, Maybe } from "~/types";
import type { InferenceFrames } from "../../inference";
import { getInferenceRecordType, getInferenceType } from "../../inference";
import type { ImagePanel } from "../../panels";
import {
  checkIsImagePanelWithInference,
  getInferenceTopicDescriptor,
  getPrimaryTopicDescriptor,
  VisualizationType,
} from "../../panels";
import { useLoadedPlaybackSource, usePlaybackSettings } from "../../playback";
import type {
  LimitedRecordsRequest,
  LimitedRecordsTopicSubscription,
  PlayerRecord,
  RecordStore,
} from "../../record-store";
import { useStoreSnapshot } from "../../record-store";
import type { TimestepValue } from "../../types";
import { SampleFrequency, Timestep } from "../../types";
import { floorTimestampForTimestep } from "../../utils";
import { useVisualizationStoreParams } from "../context";
import type {
  ImageFrame,
  ImageFrames,
  ImageFramesRequest,
  ImageFramesSnapshot,
} from "./types";

function createRecordsRequests(
  imageTopic: Topic,
  inferenceTopic: Maybe<Topic>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
): {
  imageRequest: LimitedRecordsRequest<"image">;
  inferenceRequest: LimitedRecordsRequest<
    "image" | "detections" | "segmentations"
  > | null;
} {
  const frequency = {
    [Timestep.Second]: SampleFrequency.Second,
    [Timestep.HalfSecond]: SampleFrequency.HalfSecond,
    [Timestep.Decisecond]: SampleFrequency.Decisecond,
  }[timestep];

  const inferenceRecordType = getInferenceRecordType(inferenceTopic);
  const hasInference = inferenceTopic != null && inferenceRecordType != null;

  return {
    imageRequest: {
      recordType: "image",
      topicId: imageTopic.id,
      frequency,
      count,
      timestamp,
    },
    inferenceRequest: hasInference
      ? {
          recordType: inferenceRecordType,
          topicId: inferenceTopic.id,
          frequency,
          count,
          timestamp,
        }
      : null,
  };
}

function subscribeToStore(
  imageTopic: Topic,
  inferenceTopic: Maybe<Topic>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  limit: number,
  prefetchBehind: number,
  prefetchAhead: number,
  store: RecordStore,
  notify: () => void,
): () => void {
  const { imageRequest, inferenceRequest } = createRecordsRequests(
    imageTopic,
    inferenceTopic,
    timestep,
    count,
    timestamp,
  );

  const sharedSubscriptionFields: Pick<
    LimitedRecordsTopicSubscription<"image">,
    "limit" | "prefetchBehind" | "prefetchAhead" | "notify"
  > = {
    limit,
    prefetchBehind,
    prefetchAhead,
    notify,
  };

  const unsubscribeFns = [
    store.subscribe({
      ...imageRequest,
      ...sharedSubscriptionFields,
      topicStartTime: imageTopic.startTime,
      topicEndTime: imageTopic.endTime,
    }),
    inferenceTopic != null &&
      inferenceRequest != null &&
      store.subscribe({
        ...inferenceRequest,
        ...sharedSubscriptionFields,
        topicStartTime: inferenceTopic.startTime,
        topicEndTime: inferenceTopic.endTime,
      }),
  ].filter((fn) => typeof fn === "function");

  return () => {
    unsubscribeFns.forEach((fn) => fn());
  };
}

function getStoreSnapshot(
  imageTopic: Topic,
  inferenceTopic: Maybe<Topic>,
  timestep: TimestepValue,
  count: number,
  timestamp: bigint,
  store: RecordStore,
): AsyncOperationSnapshot<ImageFrames> {
  const { imageRequest, inferenceRequest } = createRecordsRequests(
    imageTopic,
    inferenceTopic,
    timestep,
    count,
    timestamp,
  );

  const imageRecordsSnapshot = store.getRecords(imageRequest);

  const inferenceRecordsSnapshot =
    inferenceRequest == null ? null : store.getRecords(inferenceRequest);

  if (imageRecordsSnapshot.status === "rejected") {
    return imageRecordsSnapshot;
  }

  if (inferenceRecordsSnapshot?.status === "rejected") {
    return inferenceRecordsSnapshot;
  }

  if (imageRecordsSnapshot.status === "pending") {
    return imageRecordsSnapshot;
  }

  if (inferenceRecordsSnapshot?.status === "pending") {
    return inferenceRecordsSnapshot;
  }

  const { value: imageRecords } = imageRecordsSnapshot;

  const playbackFrameTimestamp = floorTimestampForTimestep(timestamp, timestep);

  const mostRecentRecordIndex = imageRecords.findLastIndex(
    (record) =>
      floorTimestampForTimestep(record.timestamp, timestep) <=
      playbackFrameTimestamp,
  );

  const mostRecentRecord: PlayerRecord<"image"> | undefined =
    imageRecords[mostRecentRecordIndex];

  let currentImageFrame: ImageFrame | null = null;
  if (mostRecentRecord !== undefined) {
    const freshDuration = {
      [Timestep.Second]: secondsToNanoseconds(2),
      [Timestep.HalfSecond]: secondsToNanoseconds(1),
      [Timestep.Decisecond]: millisecondsToNanoseconds(200),
    }[timestep];

    const isStale =
      playbackFrameTimestamp -
        floorTimestampForTimestep(mostRecentRecord.timestamp, timestep) >
      freshDuration;

    let inferenceFrames: InferenceFrames | null = null;
    if (inferenceTopic != null && inferenceRecordsSnapshot != null) {
      const inferenceRecord = inferenceRecordsSnapshot.value.find(
        (record) => record.timestamp === mostRecentRecord.timestamp,
      );

      inferenceFrames = {
        // The inference topic snapshot should only be defined if both the
        // inference topic is defined AND it has a known inference type, so this
        // non-null assertion should be safe.
        type: getInferenceType(inferenceTopic)!,
        topicId: inferenceTopic.id,
        current: inferenceRecord?.data ?? null,
      } as InferenceFrames;
    }

    currentImageFrame = {
      timestamp: mostRecentRecord.timestamp,
      isStale,
      data: mostRecentRecord.data,
      inferenceFrames,
    };
  }

  return {
    status: "fulfilled",
    value: {
      current: currentImageFrame,
      nextTimestamp: imageRecords[mostRecentRecordIndex + 1]?.timestamp ?? null,
    },
  };
}

export function useImageFrames({
  panel,
  topic: imageTopic,
  inferenceTopic,
}: {
  panel: ImagePanel;
  topic: Topic;
  inferenceTopic: Maybe<Topic>;
}): {
  snapshot: ImageFramesSnapshot;
  isPlaceholder: boolean;
} {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = useLoadedPlaybackSource();

  const { count, limit, prefetchBehind, prefetchAhead } =
    useVisualizationStoreParams(VisualizationType.Image);

  const imageTopicDescriptor = getPrimaryTopicDescriptor(panel);
  const inferenceTopicDescriptor = checkIsImagePanelWithInference(panel)
    ? getInferenceTopicDescriptor(panel)
    : null;

  return useStoreSnapshot(
    useMemo(() => {
      const request: ImageFramesRequest = {
        imageTopic,
        imageTopicDescriptor,
        inferenceTopic,
        inferenceTopicDescriptor,
        timestamp: playbackSource.timestamp,
        timestep: playbackSettings.timestep,
      };

      return {
        request,
        subscribe: subscribeToStore.bind(
          null,
          imageTopic,
          inferenceTopic,
          playbackSettings.timestep,
          count,
          playbackSource.timestamp,
          limit,
          prefetchBehind,
          prefetchAhead,
        ),
        getSnapshot: getStoreSnapshot.bind(
          null,
          imageTopic,
          inferenceTopic,
          playbackSettings.timestep,
          count,
          playbackSource.timestamp,
        ),
      };
    }, [
      imageTopic,
      imageTopicDescriptor,
      inferenceTopic,
      inferenceTopicDescriptor,
      playbackSource.timestamp,
      playbackSettings.timestep,
      count,
      limit,
      prefetchBehind,
      prefetchAhead,
    ]),
  );
}
