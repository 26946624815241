import React from "react";
import { Settings } from "@mui/icons-material";
import { DocumentTitle } from "~/components/document-title";
import * as Layout from "~/layout";
import { SettingsSidebar } from "~/settings";

export function DataStoreDashboardPage({
  showMap,
  thumbnailsSection,
  mapSection,
  localSettings,
}: {
  showMap: boolean;
  thumbnailsSection: React.ReactNode;
  mapSection: React.ReactNode;
  localSettings?: React.ReactNode;
}) {
  return (
    <>
      <DocumentTitle>Dashboard</DocumentTitle>
      <Layout.LayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation />
          <Layout.Header
            title="Dashboard"
            actions={
              <Layout.SidebarTrigger
                title="Settings"
                sidebarId="settings"
                icon={<Settings />}
              />
            }
          />
          <Layout.Main>
            {showMap ? (
              mapSection
            ) : (
              <Layout.ScrollableContainer
                // Used for thumbnail cards' IntersectionObservers
                data-scroll-root=""
              >
                <Layout.FullWidthContainer>
                  {thumbnailsSection}
                </Layout.FullWidthContainer>
              </Layout.ScrollableContainer>
            )}
          </Layout.Main>
          <Layout.Sidebar>
            <Layout.SidebarSwitch
              options={[
                {
                  sidebarId: "settings",
                  element: <SettingsSidebar>{localSettings}</SettingsSidebar>,
                },
              ]}
            />
          </Layout.Sidebar>
        </Layout.Root>
      </Layout.LayoutStateProvider>
    </>
  );
}
