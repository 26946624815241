import React, { useState } from "react";
import {
  ExpandLess,
  ExpandMore,
  Public,
  Search,
  Tune,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Collapse,
  collapseClasses,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  selectClasses,
  Stack,
  Switch,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@mui/material";
import type { PopupState } from "material-ui-popup-state/hooks";
import { bindPopover } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import type { Control } from "react-hook-form";
import type { ValueOf } from "type-fest";
import * as z from "zod";
import {
  ComboBox,
  ResourceSelectInput,
  TextField,
  useStudioForm,
} from "~/components/Form";
import type { Option } from "~/components/Form/types";
import { Pagination } from "~/components/Pagination";
import { useSearchRequest } from "~/components/Table";
import { Text } from "~/components/text";
import {
  filterArray,
  filterText,
  filterUuid,
  optionalText,
  requiredUuid,
} from "~/domain/common";
import { useAllGroups, useAllLabels } from "~/domain/logs";
import { useIsMobile } from "~/layout";
import type {
  GroupListResponse,
  LabelListResponse,
  ListLogsRequest,
  Log,
} from "~/lqs";
import {
  useUpdateLog,
  useUserQueryOptionsFactory,
  useUsersQueryOptionsFactory,
} from "~/lqs";
import type { LabelProcessor } from "~/pages";
import {
  DataStoreDashboardPage,
  LogMap,
  LogThumbnails,
  useLogMapQuery,
  useLogThumbnailsQuery,
  useMobileFilters,
} from "~/pages";
import type { Maybe } from "~/types";
import { pluralize } from "~/utils/pluralize";
import { StorageItem, useStorageValue } from "~/utils/storage";

const LIMIT_OPTIONS = [15, 25, 50];

const SortOptions = {
  Newest: "newest",
  Oldest: "oldest",
} as const;

const filterSchema = z.object({
  name: filterText,
});

const requestSchema = filterSchema
  .extend({
    groupIds: filterArray(requiredUuid).catch([]),
    labelIds: filterArray(requiredUuid).catch([]),
    createdBy: filterUuid.catch(null),
    limit: z.coerce
      .number()
      .refine((arg) => LIMIT_OPTIONS.includes(arg))
      .catch(LIMIT_OPTIONS[1]),
    offset: z.coerce.number().int().nonnegative().catch(0),
    sort: z.nativeEnum(SortOptions).catch(SortOptions.Newest),
  })
  .transform((arg) => {
    // Offset should be multiple of limit
    const isValidOffset = arg.offset % arg.limit === 0;

    return {
      ...arg,
      offset: isValidOffset ? arg.offset : 0,
    };
  });

function useLogsSearchRequest() {
  return useSearchRequest(requestSchema);
}

type LogsSearchRequest = ReturnType<typeof useLogsSearchRequest>[0];
type SetLogsSearchRequest = ReturnType<typeof useLogsSearchRequest>[1];

function NameField({ control }: { control: Control<{ name: string | null }> }) {
  return (
    <TextField
      control={control}
      name="name"
      size="small"
      noHelperText
      endAdornment={
        <InputAdornment position="end">
          <Tooltip title="Search">
            <IconButton type="submit" edge="end" size="small">
              <Search fontSize="small" />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      }
    />
  );
}

function SortField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleSortChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setRequest({ sort: e.target.value as any });
  }

  return (
    <MuiTextField
      select
      size="small"
      label="Sort by"
      value={request.sort}
      onChange={handleSortChange}
      sx={{
        width: "12ch",
        flex: "none",
        [`& .${selectClasses.select}`]: {
          boxSizing: "border-box",
          height: 40,
          fontSize: ".875rem",
        },
        [`& .${selectClasses.icon}`]: {
          // Font size usually applied when an SVG icon has `size="small"` prop.
          // Doesn't appear to be a simple way to pass that prop to the select.
          fontSize: "1.25rem",
        },
      }}
    >
      <MenuItem value={SortOptions.Newest}>Newest</MenuItem>
      <MenuItem value={SortOptions.Oldest}>Oldest</MenuItem>
    </MuiTextField>
  );
}

const sortMapping: Record<
  ValueOf<typeof SortOptions>,
  Pick<ListLogsRequest, "sort" | "order">
> = {
  [SortOptions.Newest]: { sort: "desc", order: "start_time" },
  [SortOptions.Oldest]: { sort: "asc", order: "start_time" },
};

function PaginationControls({
  disableJumping,
  logCount,
  request,
  setRequest,
}: {
  disableJumping?: boolean;
  logCount: number;
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleLimitChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setRequest({ limit: Number(e.target.value) });
  }

  function handleOffsetChange(newOffset: number): void {
    setRequest({ offset: newOffset });
  }

  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <MuiTextField
        select
        size="small"
        label="Results per page"
        value={request.limit}
        onChange={handleLimitChange}
        sx={{ width: "15ch" }}
      >
        {LIMIT_OPTIONS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </MuiTextField>
      <Pagination
        disableJumping={disableJumping}
        count={logCount}
        limit={request.limit}
        offset={request.offset}
        onChange={handleOffsetChange}
      />
    </Stack>
  );
}

function GroupsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  const optionsQuery = useAllGroups({ select: selectGroupOptions });

  function handleChange(newGroupIds: Array<string>): void {
    setRequest({ groupIds: newGroupIds });
  }

  return (
    <ComboBox
      name="group"
      value={request.groupIds}
      onChange={handleChange}
      optionsQuery={optionsQuery}
      size="small"
      noHelperText
    />
  );
}

function selectGroupOptions(
  response: GroupListResponse,
): ReadonlyArray<Option> {
  return response.data.map((group) => ({
    value: group.id,
    label: group.name,
  }));
}

function LabelsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  const optionsQuery = useAllLabels({ select: selectLabelOptions });

  function handleChange(newLabelIds: Array<string>): void {
    setRequest({ labelIds: newLabelIds });
  }

  return (
    <ComboBox
      name="tags"
      value={request.labelIds}
      onChange={handleChange}
      optionsQuery={optionsQuery}
      size="small"
      noHelperText
    />
  );
}

function selectLabelOptions(
  response: LabelListResponse,
): ReadonlyArray<Option> {
  return response.data.map((label) => ({
    value: label.id,
    label: label.value,
  }));
}

function CreatedByField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  const createUserListQueryOptions = useUsersQueryOptionsFactory();
  const createUserFetchQueryOptions = useUserQueryOptionsFactory();

  function handleChange(newCreatedBy: string | null): void {
    setRequest({ createdBy: newCreatedBy });
  }

  return (
    <ResourceSelectInput
      name="createdBy"
      size="small"
      noHelperText
      value={request.createdBy}
      onChange={handleChange}
      createListQueryOptions={(text) =>
        createUserListQueryOptions({
          usernameLike: text,
          limit: 5,
          sort: "asc",
          order: "username",
        })
      }
      createFetchQueryOptions={createUserFetchQueryOptions}
      selectOption={(user) => ({
        value: user.id,
        label: user.username,
      })}
    />
  );
}

function ThumbnailsFilters({
  request,
  setRequest,
  logCount,
  onOpenMap,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
  logCount: Maybe<number>;
  onOpenMap: () => void;
}) {
  const { control, handleSubmit } = useStudioForm({
    schema: filterSchema,
    values: { name: request.name },
    onSubmit: setRequest,
  });

  const isMobile = useIsMobile();

  const mobileFilters = useMobileFilters();

  if (isMobile) {
    return (
      <>
        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
          <Tooltip title="Filters">
            <IconButton size="small" onClick={mobileFilters.toggle}>
              <Tune fontSize="small" />
            </IconButton>
          </Tooltip>
          <Typography>
            {logCount != null && pluralize(logCount, "log")}
          </Typography>
          <Button
            sx={{ ml: "auto" }}
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            startIcon={<Public />}
            onClick={onOpenMap}
          >
            Open Map
          </Button>
        </Stack>
        <Collapse in={mobileFilters.show}>
          <Stack spacing={1.5} sx={{ mt: 1.5 }}>
            <GroupsField request={request} setRequest={setRequest} />
            <LabelsField request={request} setRequest={setRequest} />
            <CreatedByField request={request} setRequest={setRequest} />
            <Stack direction="row" spacing={1.5} sx={{ alignItems: "center" }}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ width: "100%" }}
              >
                <NameField control={control} />
              </Box>
              <SortField request={request} setRequest={setRequest} />
            </Stack>
          </Stack>
        </Collapse>
      </>
    );
  } else {
    return (
      <Stack spacing={1.5}>
        <Stack
          direction="row"
          spacing={1.5}
          sx={{ alignItems: "center", flexWrap: "wrap" }}
        >
          <Box sx={{ width: "25ch" }}>
            <GroupsField request={request} setRequest={setRequest} />
          </Box>
          <Box sx={{ width: "34ch" }}>
            <LabelsField request={request} setRequest={setRequest} />
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ width: "18ch" }}
          >
            <NameField control={control} />
          </Box>
          <Box sx={{ width: "29ch" }}>
            <CreatedByField request={request} setRequest={setRequest} />
          </Box>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            startIcon={<Public />}
            onClick={onOpenMap}
            sx={{ ml: "auto" }}
          >
            Open Map
          </Button>
        </Stack>
        <Stack
          direction="row"
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Typography>
            {logCount != null && pluralize(logCount, "log")}
          </Typography>
          <SortField request={request} setRequest={setRequest} />
        </Stack>
      </Stack>
    );
  }
}

function MapFilters({
  request,
  setRequest,
  logCount,
  results,
  onCloseMap,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
  logCount: Maybe<number>;
  results: React.ReactNode;
  onCloseMap: () => void;
}) {
  const { control, handleSubmit } = useStudioForm({
    schema: filterSchema,
    values: { name: request.name },
    onSubmit: setRequest,
  });

  const isMobile = useIsMobile();

  const mobileFilters = useMobileFilters();

  if (isMobile) {
    return (
      <>
        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
          <Tooltip title="Search results">
            <IconButton size="small" onClick={mobileFilters.toggle}>
              {mobileFilters.show ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <Typography>
            {logCount != null && pluralize(logCount, "log")}
          </Typography>
          <Button
            sx={{ ml: "auto" }}
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            onClick={onCloseMap}
          >
            Exit Map
          </Button>
        </Stack>
        <Collapse
          in={mobileFilters.show}
          sx={{
            overflowY: "auto",
            [`&:not(.${collapseClasses.hidden})`]: {
              mt: 1.5,
            },
          }}
        >
          <Stack spacing={1.5} sx={{ mt: 1 }}>
            <GroupsField request={request} setRequest={setRequest} />
            <LabelsField request={request} setRequest={setRequest} />
            <CreatedByField request={request} setRequest={setRequest} />
            <Stack direction="row" spacing={1.5}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ width: "100%" }}
              >
                <NameField control={control} />
              </Box>
              <SortField request={request} setRequest={setRequest} />
            </Stack>
          </Stack>
          {results}
        </Collapse>
      </>
    );
  } else {
    return (
      <>
        <Stack spacing={1.5}>
          <GroupsField request={request} setRequest={setRequest} />
          <LabelsField request={request} setRequest={setRequest} />
          <Stack
            direction="row"
            spacing={1.5}
            sx={{
              alignItems: "center",
            }}
          >
            <Box sx={{ minWidth: 0, flex: "1 0" }}>
              <CreatedByField request={request} setRequest={setRequest} />
            </Box>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ width: "17ch" }}
            >
              <NameField control={control} />
            </Box>
          </Stack>
          <Stack direction="row" spacing={1.5} sx={{ alignItems: "center" }}>
            <SortField request={request} setRequest={setRequest} />
            <Typography sx={{ mr: "auto" }}>
              {logCount != null && pluralize(logCount, "log")}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={onCloseMap}
            >
              Exit Map
            </Button>
          </Stack>
        </Stack>
        {results}
      </>
    );
  }
}

function LogNoteForm({
  log,
  popupState,
}: {
  log: Log;
  popupState: PopupState;
}) {
  const updateLog = useUpdateLog(log.id);

  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useStudioForm({
    schema: z.object({
      note: optionalText,
    }),
    defaultValues: {
      note: log.note,
    },
    onSubmit(values) {
      updateLog.mutate(values, {
        onSuccess() {
          enqueueSnackbar("Note updated", { variant: "success" });
        },
        onError() {
          enqueueSnackbar("Unable to update note", { variant: "error" });
        },
      });
    },
  });

  function handleClose(): void {
    popupState.close();
  }

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            width: "50ch",
            p: 1,
          },
        },
      }}
    >
      <Stack spacing={1} component="form" onSubmit={handleSubmit} noValidate>
        <Text bold>Log Note</Text>
        <TextField control={control} name="note" multiline />
        <Stack direction="row" spacing={1}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            loading={updateLog.isLoading}
            disableElevation
          >
            Update
          </LoadingButton>
          <Button
            type="button"
            color="secondary"
            variant="text"
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
}

function StudioLogThumbnailsSection({
  request,
  setRequest,
  baseListRequest,
  processLabels,
  onOpenMap,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
  baseListRequest: ListLogsRequest;
  processLabels: LabelProcessor;
  onOpenMap: () => void;
}) {
  const thumbnailsQuery = useLogThumbnailsQuery(baseListRequest);

  return (
    <LogThumbnails
      logsQuery={thumbnailsQuery}
      renderNoteForm={({ log, popupState }) => (
        <LogNoteForm log={log} popupState={popupState} />
      )}
      processLabels={processLabels}
      filters={
        <ThumbnailsFilters
          request={request}
          setRequest={setRequest}
          logCount={thumbnailsQuery.data?.count}
          onOpenMap={onOpenMap}
        />
      }
      pagination={
        thumbnailsQuery.isSuccess && (
          <PaginationControls
            logCount={thumbnailsQuery.data.count}
            request={request}
            setRequest={setRequest}
          />
        )
      }
    />
  );
}

function StudioLogMapSection({
  request,
  setRequest,
  baseListRequest,
  processLabels,
  onCloseMap,
  autoZoom,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
  baseListRequest: ListLogsRequest;
  processLabels: LabelProcessor;
  onCloseMap: () => void;
  autoZoom: boolean;
}) {
  const mapQuery = useLogMapQuery(baseListRequest);

  return (
    <LogMap
      logsQuery={mapQuery}
      renderNoteForm={({ log, popupState }) => (
        <LogNoteForm log={log} popupState={popupState} />
      )}
      processLabels={processLabels}
      renderFilters={({ results }) => (
        <MapFilters
          request={request}
          setRequest={setRequest}
          logCount={mapQuery.data?.count}
          results={results}
          onCloseMap={onCloseMap}
        />
      )}
      pagination={
        mapQuery.isSuccess && (
          <PaginationControls
            disableJumping
            logCount={mapQuery.data.count}
            request={request}
            setRequest={setRequest}
          />
        )
      }
      autoZoom={autoZoom}
    />
  );
}

const MAX_VISIBLE_LABELS_MOBILE = 3;
const MAX_VISIBLE_LABELS_NON_MOBILE = 5;

const mapAutoZoomStorageItem = StorageItem.usingSessionStorage({
  version: 0,
  key: "dashboard-map-auto-zoom",
  parser: z.boolean(),
  defaultValue: true,
});

export function StudioDataStoreDashboardPage() {
  const [showMap, setShowMap] = useState(false);

  const [request, setRequest] = useLogsSearchRequest();

  const autoZoomMap = useStorageValue(mapAutoZoomStorageItem);

  const tagLabelIds = request.labelIds;

  const baseListRequest: ListLogsRequest = {
    nameLike: request.name,
    groupIdIn: request.groupIds,
    tagLabelIdsIncludes: tagLabelIds,
    createdBy: request.createdBy,
    limit: request.limit,
    offset: request.offset,
    ...sortMapping[request.sort],
  };

  const isMobile = useIsMobile();

  function handleOpenMap(): void {
    setShowMap(true);
    setRequest({ offset: 0 });
  }

  function handleCloseMap(): void {
    setShowMap(false);
    setRequest({ offset: 0 });
  }

  const processLabels: LabelProcessor = (labels) => {
    const processedLabels = labels.map((label) => ({
      label,
      selected: tagLabelIds.some((labelId) => label.id === labelId),
    }));

    processedLabels.sort((a, b) =>
      // Sort in ascending alphabetical order
      a.label.value.localeCompare(b.label.value),
    );

    const maxVisible = isMobile
      ? MAX_VISIBLE_LABELS_MOBILE
      : MAX_VISIBLE_LABELS_NON_MOBILE;

    const visible = processedLabels.slice(0, maxVisible);
    const truncated = processedLabels.slice(visible.length);

    return {
      visible,
      truncated,
    };
  };

  function handleSetMapAutoZoom(_: unknown, checked: boolean): void {
    mapAutoZoomStorageItem.set(checked);
  }

  let localSettings = null;
  if (showMap) {
    localSettings = (
      <FormControlLabel
        sx={{ justifyContent: "space-between", ml: 0 }}
        control={
          <Switch checked={autoZoomMap} onChange={handleSetMapAutoZoom} />
        }
        label="Zoom to results"
        labelPlacement="start"
      />
    );
  }

  return (
    <DataStoreDashboardPage
      showMap={showMap}
      thumbnailsSection={
        <StudioLogThumbnailsSection
          request={request}
          setRequest={setRequest}
          baseListRequest={baseListRequest}
          processLabels={processLabels}
          onOpenMap={handleOpenMap}
        />
      }
      mapSection={
        <StudioLogMapSection
          request={request}
          setRequest={setRequest}
          baseListRequest={baseListRequest}
          processLabels={processLabels}
          onCloseMap={handleCloseMap}
          autoZoom={autoZoomMap}
        />
      }
      localSettings={localSettings}
    />
  );
}
