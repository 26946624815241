import type {
  QueryClient,
  QueryFunctionContext,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { StrictExclude, StrictOmit } from "ts-essentials";
import type { ListResponse } from "~/domain/common";
import { assertCountableListResponse } from "~/domain/common";
import { invariant } from "~/lib/invariant";
import type { Maybe } from "~/types";
import { scheduleQueriesRemoval } from "~/utils";
import { useDataStoreClients } from "../context";
import type { DataStoreClients } from "../types";

export type LqsQueryOptions<
  TQueryFnData,
  TAdditionalKeys extends StrictExclude<
    keyof UseQueryOptions<TQueryFnData>,
    "queryKey" | "queryFn"
  > = never,
> = Required<
  Pick<UseQueryOptions<TQueryFnData>, "queryKey" | "queryFn" | TAdditionalKeys>
>;

export type LqsMutationOptions<
  TData,
  TVariables,
  TAdditionalKeys extends StrictExclude<
    keyof UseMutationOptions<TData, unknown, TVariables>,
    "mutationFn"
  > = never,
> = Required<
  Pick<
    UseMutationOptions<TData, unknown, TVariables>,
    "mutationFn" | TAdditionalKeys
  >
>;

export function mergeEnabledOption(
  options: UseQueryOptions<any, any, any, any> | undefined,
  enabledOverride: boolean,
): boolean {
  return enabledOverride && (options?.enabled ?? true);
}

export async function circumventPagination<
  TRequest extends {
    limit?: Maybe<number>;
    offset?: Maybe<number>;
    includeCount?: Maybe<boolean>;
  },
  TData,
>(
  listItems: (
    request: TRequest,
    init: RequestInit,
  ) => Promise<ListResponse<TData>>,
  requestLimit: number,
  request: TRequest,
  init: RequestInit,
  maxFetches = Infinity,
): Promise<ListResponse<TData>> {
  const firstPageRequest = {
    ...request,
    limit: requestLimit,
    offset: 0,
    includeCount: true,
  };
  const firstPageResponse = await listItems(firstPageRequest, init);

  assertCountableListResponse(firstPageResponse);

  if (firstPageResponse.count <= firstPageResponse.limit) {
    // No additional pages to fetch
    return {
      ...firstPageResponse,
      limit: -1,
    };
  }

  // More than `requestLimit` items exist for this request so the remaining
  // pages need to be fetched. Now that the exact count is known they
  // can be fetched in parallel. However, no more than `maxFetches` requests
  // should be made in total.
  const remainingPagesCount = Math.min(
    Math.ceil(
      (firstPageResponse.count - firstPageRequest.limit) /
        firstPageRequest.limit,
    ),
    // One fetch has already been completed
    maxFetches - 1,
  );
  const remainingPageRequests: Array<typeof firstPageRequest> = [];
  for (let page = 1; page <= remainingPagesCount; page++) {
    remainingPageRequests.push({
      ...firstPageRequest,
      offset: firstPageRequest.limit * page,
      includeCount: false,
    });
  }

  const remainingPageResponses = await Promise.all(
    remainingPageRequests.map((pageRequest) => listItems(pageRequest, init)),
  );

  const data = firstPageResponse.data.concat(
    ...remainingPageResponses.map(({ data }) => data),
  );

  return {
    ...firstPageResponse,
    limit: -1,
    count: data.length,
    data,
  };
}

// Not fully type-safe. Lies to the query client about what the list queries'
// shapes are.
export function getInitialDetailsData<TResource>(
  queryClient: QueryClient,
  listQueryKey: QueryKey,
  predicate: (resource: TResource) => boolean,
): { data: TResource } | undefined {
  const listQueries = queryClient.getQueriesData<{ data: TResource[] }>({
    queryKey: listQueryKey,
    predicate: (query) => query.state.status === "success",
  });

  for (const [, query] of listQueries) {
    if (query === undefined) {
      continue;
    }

    for (const resource of query.data) {
      if (predicate(resource)) {
        return { data: resource };
      }
    }
  }
}

type CrudHookQueryFn<TRequest, TResponse, TQueryKey extends QueryKey> = (
  context: QueryFunctionContext<TQueryKey>,
  clients: DataStoreClients,
  request: TRequest,
) => Promise<TResponse>;

type AllKeys<TBaseQueryKey extends string> = readonly [TBaseQueryKey];

type ListsKey<TBaseQueryKey extends string> = readonly [
  ...AllKeys<TBaseQueryKey>,
  "list",
];

type ListKey<
  TBaseQueryKey extends string,
  TListRequest extends object,
> = readonly [...ListsKey<TBaseQueryKey>, TListRequest];

type FetchesKey<TBaseQueryKey extends string> = readonly [
  ...AllKeys<TBaseQueryKey>,
  "fetch",
];

type FetchKey<TBaseQueryKey extends string, TIdentifier> = readonly [
  ...FetchesKey<TBaseQueryKey>,
  TIdentifier | null,
];

interface ResourceQueryKeyFactory<
  TBaseQueryKey extends string,
  TIdentifier,
  TListRequest extends object,
> {
  all: AllKeys<TBaseQueryKey>;
  lists: () => ListsKey<TBaseQueryKey>;
  list: (request: TListRequest) => ListKey<TBaseQueryKey, TListRequest>;
  fetches: () => FetchesKey<TBaseQueryKey>;
  fetch: (
    identifier: TIdentifier | null,
  ) => FetchKey<TBaseQueryKey, TIdentifier>;
}

interface CreateResourceCrudHooksParams<
  TBaseQueryKey extends string,
  TResource extends object,
  TIdentifier,
  TListRequest extends object,
  TListResponse extends { data: Array<TResource> },
  TCreateRequest extends object,
  TUpdateRequest extends object,
> {
  baseQueryKey: TBaseQueryKey;
  getIdentifier: (resource: TResource) => TIdentifier;
  listResource: CrudHookQueryFn<
    TListRequest,
    TListResponse,
    ListKey<TBaseQueryKey, TListRequest>
  >;
  fetchResource: CrudHookQueryFn<
    TIdentifier,
    { data: TResource },
    FetchKey<TBaseQueryKey, TIdentifier>
  >;
  createResource: (
    clients: DataStoreClients,
    request: TCreateRequest,
  ) => Promise<{ data: TResource }>;
  updateResource: (
    clients: DataStoreClients,
    identifier: TIdentifier,
    request: TUpdateRequest,
  ) => Promise<{ data: TResource }>;
  deleteResource: (
    clients: DataStoreClients,
    identifier: TIdentifier,
  ) => Promise<void>;
}

interface CreateResourceCrudHooksReturn<
  TBaseQueryKey extends string,
  TIdentifier,
  TListRequest extends object,
  TListResponse extends { data: Array<TResource> },
  TResource extends object,
  TCreateRequest extends object,
  TUpdateRequest extends object,
> {
  queryKeyFactory: ResourceQueryKeyFactory<
    TBaseQueryKey,
    TIdentifier,
    TListRequest
  >;
  useList: <TData = TListResponse>(
    request: TListRequest,
    options?: UseQueryOptions<
      TListResponse,
      unknown,
      TData,
      ListKey<TBaseQueryKey, TListRequest>
    >,
  ) => UseQueryResult<TData>;
  useFetch: <TData = { data: TResource }>(
    identifier: TIdentifier | null,
    options?: StrictOmit<
      UseQueryOptions<
        { data: TResource },
        unknown,
        TData,
        FetchKey<TBaseQueryKey, TIdentifier>
      >,
      "initialData"
    >,
  ) => UseQueryResult<TData>;
  useCreate: () => UseMutationResult<
    { data: TResource },
    unknown,
    TCreateRequest
  >;
  useUpdate: (
    identifier: TIdentifier,
  ) => UseMutationResult<{ data: TResource }, unknown, TUpdateRequest>;
  useDelete: (
    identifier: TIdentifier,
  ) => UseMutationResult<void, unknown, void>;
}

export function createResourceCrudHooks<
  const TBaseQueryKey extends string,
  TResource extends object,
  TIdentifier,
  TListRequest extends object,
  TListResponse extends { data: Array<TResource> },
  TCreateRequest extends object,
  TUpdateRequest extends object,
>({
  baseQueryKey,
  getIdentifier,
  listResource,
  fetchResource,
  createResource,
  updateResource,
  deleteResource,
}: CreateResourceCrudHooksParams<
  TBaseQueryKey,
  TResource,
  TIdentifier,
  TListRequest,
  TListResponse,
  TCreateRequest,
  TUpdateRequest
>): CreateResourceCrudHooksReturn<
  TBaseQueryKey,
  TIdentifier,
  TListRequest,
  TListResponse,
  TResource,
  TCreateRequest,
  TUpdateRequest
> {
  const queryKeyFactory: ResourceQueryKeyFactory<
    TBaseQueryKey,
    TIdentifier,
    TListRequest
  > = {
    all: [baseQueryKey],
    lists: () => [...queryKeyFactory.all, "list"],
    list: (request) => [...queryKeyFactory.lists(), request],
    fetches: () => [...queryKeyFactory.all, "fetch"],
    fetch: (identifier) => [...queryKeyFactory.fetches(), identifier],
  };

  return {
    queryKeyFactory,
    useList(request, options) {
      const clients = useDataStoreClients();

      return useQuery({
        queryKey: queryKeyFactory.list(request),
        queryFn(context) {
          return listResource(context, clients, request);
        },
        ...options,
      });
    },
    useFetch(identifier, options) {
      const queryClient = useQueryClient();

      const clients = useDataStoreClients();

      const isIdentifierValid = identifier !== null;

      return useQuery({
        queryKey: queryKeyFactory.fetch(identifier),
        queryFn(context) {
          invariant(isIdentifierValid, "ID cannot be null");

          return fetchResource(context, clients, identifier);
        },
        ...options,
        enabled: mergeEnabledOption(options, isIdentifierValid),
        initialData() {
          if (!isIdentifierValid) {
            return;
          }

          return getInitialDetailsData(
            queryClient,
            queryKeyFactory.lists(),
            (data: TResource) => getIdentifier(data) === identifier,
          );
        },
      });
    },
    useCreate() {
      const queryClient = useQueryClient();

      const clients = useDataStoreClients();

      return useMutation({
        mutationFn(request) {
          return createResource(clients, request);
        },
        onSuccess(response) {
          queryClient.removeQueries({
            queryKey: queryKeyFactory.lists(),
            type: "inactive",
          });
          queryClient.setQueryData(
            queryKeyFactory.fetch(getIdentifier(response.data)),
            response,
          );
          return queryClient.invalidateQueries({
            queryKey: queryKeyFactory.lists(),
          });
        },
      });
    },
    useUpdate(identifier) {
      const queryClient = useQueryClient();

      const clients = useDataStoreClients();

      return useMutation({
        mutationFn(updates) {
          return updateResource(clients, identifier, updates);
        },
        onSuccess(response) {
          queryClient.removeQueries({
            queryKey: queryKeyFactory.lists(),
            type: "inactive",
          });
          queryClient.setQueryData(queryKeyFactory.fetch(identifier), response);
          return queryClient.invalidateQueries({
            queryKey: queryKeyFactory.lists(),
          });
        },
      });
    },
    useDelete(identifier) {
      const queryClient = useQueryClient();

      const clients = useDataStoreClients();

      return useMutation({
        mutationFn() {
          return deleteResource(clients, identifier);
        },
        onSuccess() {
          queryClient.removeQueries({ queryKey: queryKeyFactory.lists() });
          scheduleQueriesRemoval(() =>
            queryClient.removeQueries({
              queryKey: queryKeyFactory.fetch(identifier),
            }),
          );
        },
      });
    },
  };
}
